<template lang="html">
  <div class="container-lg">
    <div class="row">
      <div class="col-md-12 mt-3">
        <div class="card">
          <div class="card-body">
            <h2>Finde deinen perfekten Ausbildungsplatz!</h2>
            <p>
              Wir helfen dir dabei Firmen zu finden und Kontakt aufzunehmen, die
              deine Werte und Einstellungen teilen. Anhand deiner Daten, die du
              uns zur Verfügung stellst, werden wir für dich den richtigenen
              Ausbildungsplatz bei den richtigen Firmen finden. Es liegt dabei
              in deiner Hand, ob du bei den Firmen, die sich dann an dich wenden
              Kontakt aufnimmst.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 mt-3">
        <div
          class="card"
          style="cursor: pointer"
          v-on:click="PushTo('/signin')"
          id="loginUser"
        >
          <div class="card-body text-center">
            <font-awesome-icon
              :icon="['fas', 'right-from-bracket']"
              class="fa-5x"
              style="color: #198fcf"
            />
          </div>
          <div class="text-center">Anmeldung Azubi</div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 mt-3">
        <div
          class="card"
          style="cursor: pointer"
          v-on:click="PushTo('/signup')"
          id="addUser"
        >
          <div class="card-body text-center">
            <font-awesome-icon
              :icon="['fas', 'user-plus']"
              class="fa-5x"
              style="color: #198fcf"
            />
          </div>
          <div class="text-center">Ich bin neu!</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 mt-3" v-if="false">
        <div class="card">
          <div
            class="card-body text-center"
            style="cursor: pointer"
            v-on:click="PushTo('/companySignin')"
          >
            <font-awesome-icon
              :icon="['fas', 'building-circle-arrow-right']"
              class="fa-5x"
              style="color: #bc0101"
            />
            <fa class="fa-5x" icon="building" style="color: #bc0101" />
          </div>
          <div class="text-center">Anmeldung Firmen</div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 mt-3" v-if="false">
        <div
          class="card"
          style="cursor: pointer"
          v-on:click="PushTo('/companySignUp')"
        >
          <div class="card-body text-center">
            <font-awesome-icon
              :icon="['fas', 'fa-plus']"
              class="fa-5x"
              style="color: #bc0101"
            />
          </div>
          <div class="text-center">Wir sind neu!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      password: null,
    };
  },
  methods: {
    PushTo(route) {
      // Avoid redundant navigation to current location error - check also main.js router.beforeEach-section

      try {
        this.$router.push(route);
      } catch (error) {
        if (!(error instanceof NavigationDuplicated)) {
          throw error;
        }
      }
    },
  },
};
</script>
