<template lang="html">

<div class="container-fluid">
	<h1 class="center"> Firmendaten </h1>
	<div class="row align-items-top">
		<div class="col-sm-12">
			<div class="card mt-3">
				<div class="card-header" >
					Firmendaten
				</div>
				<div class="card-body content-center p-2" >
					<form class="form-horizontal justify-content-center" role="form">
						<fieldset>
							<div class="row form-group">
								<div class="col-sm-12">
									<label class="control-label"  for="companyname">Firmenname</label>
									<input type="text" id="companyname" @change="setDirty()" v-model="companydata.name" class="form-control">
							  </div>
              </div>
              <div class="row form-group">
                <div class="col-sm-12">
                  <label class="control-label"  for="department">Abteilung</label>
                  <input type="text" id="department" @change="setDirty()" v-model="companydata.department" class="form-control">
                </div>
              </div>
              <div class="mt-2">
                Ansprechpartner
              </div>
              <div class="row form-group">
								<div class="col-sm-6">
									<label class="control-label"  for="contactfirstname">Vorname</label>
									<input type="text" id="contactfirstname" @change="setDirty()" v-model="companydata.contact_firstname" class="form-control">
								</div>
								<div class="col-sm-6">
									<label class="control-label"  for="contactlastname">Nachname</label>
									<input type="text" id="contactlastname" @change="setDirty()" v-model="companydata.contact_lastname" class="form-control">
								</div>
							</div>
							<div class="row form-group">
								<div class="col-sm-12">
                  <label class="control-label mt-2" for="email">E-mail</label>
									<input type="text" readonly id="email" @change="setDirty()" v-model="companydata.email" class="form-control" >
                </div>
							</div>
              <!-- Phone and address -->
              <div class="form-group row">
								<label class="col-sm-12 control-label mt-2" for="tel">Telefonnummer (optional)</label>
								<div class="col-sm-12">
									<input type="text" id="tel" @change="setDirty()" v-model="companydata.tel" class="form-control">
								</div>
							</div>	
              <div class="form-group row">
								<div class="col col-sm-12">
									<label class="control-label mt-2" for="copmpany_street">Straße und Hausnummer</label>
									<div class="">
										<input type="text" id="copmpany_street" @change="setDirty()" v-model="companydata.street" class="form-control">
									</div>
								</div>
              </div>
              <div class="form-group row">
                  <div class="col col-sm-12">
                    <label class="control-label mt-2" for="companySelectedCity">Postleitzahl oder Ort</label>
                    <input 
                      class="col-sm-12 form-control"
                      type="text" 
                      id="city" 
                      list="companySelectedCity" 
                      @change="setDirty()" 
                      v-model="companyZipAndCity">
                              <datalist  id ="companySelectedCity">
                                <option v-for="item in cityList">{{item.plz}} - {{item.stadt}}</option>
                              </datalist>
                    <label
                      v-if="!checkIfCityValid(companyZipAndCity)"
                      id="invalidCity" 
                      class="control-label text-danger">Die Postleitzahl/Stadt-Kombination ist nicht gültig. Bitte gib eine gültige Kombination aus der Liste an.</label>
                    <div class="text-center">
                      <button 
                        type="button"
                        id="btnResetCompanyCity" 
                        class="m-3 btn btn-outline-primary" 
                        @click="setDirty(); resetCity()">Ort/PLZ leeren
                      </button>
                    </div>
                  </div>
              </div>
            <div class="row">
              <div class="col col-sm-12 text-center">
                <button 
                        type="button"
                        id="btnSaveCompanyCity" 
                        class="btn btn-primary" 
                        @click="setDirty(); updateCompanyData()">Inhalte Speichern
                  </button>
              </div>
            </div>
					 </fieldset>
					</form>
				</div>
			</div>
    </div>

    <div class="col-sm-12">
      <div class="card mt-3">
        <div class="card-header" >
          Rechnungsanschrift
        </div>
        <div class="card-body content-center p-2" >
          <form class="form-horizontal justify-content-center" role="form">
            <fieldset>
              <div class="row form-group">
                <div class="col-sm-12">
                  <label class="control-label"  for="billingdepartment">Abteilung</label>
                  <input type="text" id="billingdepartment" @change="setDirty()" v-model="companydata.billing_department" class="form-control">
                </div>
              </div>
              <div class="mt-2">
                Ansprechpartner
              </div>
							<div class="row form-group">
								<div class="col-sm-6">
									<label class="control-label"  for="billing_contact_firstname">Vorname</label>
									<input type="text" id="billing_contact_firstname" @change="setDirty()" v-model="companydata.billing_contact_firstname" class="form-control">
								</div>
								<div class="col-sm-6">
									<label class="control-label"  for="billing_contact_lastname">Nachname</label>
									<input type="text" id="billing_contact_lastname" @change="setDirty()" v-model="companydata.billing_contact_lastname" class="form-control">
								</div>
							</div>
              <div class="row form-group">
                <div class="col-sm-12">
                  <label class="control-label mt-2" for="billing_email" placeholder="E-mail" >E-mail</label>
                  <input type="text" id="billing_email" @change="setDirty()" v-model="companydata.billing_contact_email" class="form-control" >
                </div>
              </div>
              <div class="form-group row mt-2">
                <div class="col-sm-12">
                  <label class="control-label" for="billing_contact_telefon">Telefonnummer (optional)</label>
                  <input type="text" id="billing_contact_telefon" @change="setDirty()" v-model="companydata.billing_contact_telefon" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                <div class="col col-sm-12">
                  <label class="control-label" for="billing_street">Straße und Hausnummer</label>
                  <input type="text" id="billing_street" @change="setDirty()" v-model="companydata.billing_street" class="form-control">
                </div>
              </div>
              <div class="form-group row">
                  <div class="col col-sm-12">
                    <label class="control-label mt-2" for="billing_city">Postleitzahl oder Ort</label>
                    <input 
                      class="col-sm-12 form-control"
                      type="text" 
                      id="billing_city" 
                      list="selectedbillingCity" 
                      @change="setDirty(); checkIfCityValid(billingZipAndCity)" 
                      v-model="billingZipAndCity">
                              <datalist  id ="selectedbillingCity">
                                <option v-for="item in cityList">{{item.plz}} - {{item.stadt}}</option>
                              </datalist>

                    <label
                      v-if="!checkIfCityValid(billingZipAndCity)"
                      id="invalidBillingCity" 
                      class="control-label text-danger" 
                      for="textinput">Die Postleitzahl/Stadt-Kombination ist nicht gültig. Bitte gib eine gültige Kombination aus der Liste an.</label>

                    <div class="text-center">
                      <button 
                        type="button"
                        id="btnResetBillingCity" 
                        class="m-2 btn btn-outline-primary" 
                        @click="setDirty(); resetBillingCity()">Ort/PLZ leeren
                      </button>
                    </div>
                    <div class="row">
                      <div class="col col-sm-12 text-center">
                        <button 
                          type="button"
                          id="btnSaveCompanyCity" 
                          class="btn btn-primary" 
                          @click="setDirty(); updateCompanyData()">Inhalte Speichern
                        </button>
                      </div>
                   </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
	</div>
  <!-- Security and Passwort Card-->
  <div class="row align-items-top mt-3">
		<div class="col-sm-12">
			<div class="card">
				<div class="card-header" >
					Passwort
				</div>
				<div class="card-body content-center" >
					<form class="form-horizontal justify-content-center" role="form">
						<fieldset>
              <!-- Password change block -->
							<div class="form-group row">
								<label class="control-label col-sm-12 mt-2" for="oldPw">Bisheriges Passwort (das haben wir uns sicherheitshalber nicht gemerkt)</label>
								<div class="col-sm-12">
									<input type="password" id="oldPw" v-model="companydata.company_oldPw" class="form-control">
								</div>
							</div>              
							<div class="form-group row">
								<label class="control-label col-sm-10 mt-2" for="newPw">Neues Passwort</label>
								<div class="col-sm-12">
									<input type="password" id="newPw" v-model="companydata.company_newPw" class="form-control">
								</div>
							</div>
							<div class="form-group row">
								<label class="control-label col-sm-10 mt-2" for="newPwConfirm" >Bestätigung neues Passwort</label>
								<div class="col-sm-12">
									<input type="password" id="NewPwConfirm" v-model="companydata.company_newPwConfirm" class="form-control">
								</div>
							</div>
              <div class="text-center mt-2">
										<button type="button" class="btn btn-primary" v-on:click="changePw" >Passwort ändern</button>
									</div>             
              <div class="form-group row mt-3">
                  <div class="col col-sm-12">
                    <div class="text-center mt-2">
                        <button 
                          type="button"
                          class="btn btn-primary btn-danger"  
                          v-if=false 
                          v-on:click="activateDeleteBlock()">
                          Meinen Account löschen
                        </button>
                    </div>
                    <div v-if=isDelBlockActive>									  
                        <label class="control-label" for="DeleteConfirmText">Bitte gib <span style="color:#FF0000">JA </span> ein, um den Account wirklich zu löschen</label>
									      <div class="">										
                          <input type="text" id="DeleteConfirmText" v-model="companydata.deleteActiveCompany" class="form-control">									  
                         </div>
                         <div class="text-center mt-2">
                          <button type="button" class="btn btn-outline-danger" v-on:click="keepCurrentCompany()">Behalten</button>
                            <button type="button" class="btn btn-danger" v-on:click="delCurrentCompany()">Löschen</button>
									      </div>
                     </div>            
                  </div>            
                </div>
						</fieldset>
					</form>
				</div>
			</div>
		</div>
	</div>

	<div class="row align-items-center mt-3">
		&nbsp;
	</div>
	</div>
</template>


<script>
import { awsCognitoChangeCompanyPw, awsCognitoDeleteCompany } from "../api/companyAwsCognito"


export default {
  props: ["id"],
  data() {
    return {
      cityList: null,
		  isDirty: false, 
      isDelBlockActive: false, // Delete Block is active after click on "Mein Account löschen"
      companyZipAndCity: null, // Zip code and city for company address
      billingZipAndCity: null, // Zip code and city for billing address
      companydata: {
        id: null,
        name: null,
        department: null,
        company_contact_firstname: null,
        company_contact_lastname: null,
        email: null,
        tel: null,
        street: null,
        company_zip: {
          id: null,
          zipcode: null,
          name: null,
          fullName: null,
          isValid: true,
          isEmpty: false
        },
        company_oldPw: null,
        company_newPw: null,
        deleteActiveCompany: null,
        company_newPwConfirm: null,
        billing_department: null,
        billing_contact_firstname: null,
        billing_contact_lastname: null,
        billing_contact_email: null,
        billing_contact_telefon: null,
        billing_street: null,
        billing_zip: {
          id: null,
          zipcode: null,
          name: null,
          fullName: null,
          isValid: true,
          isEmpty: false
        },
        job:{
          id:null,
          name: null
        },
        jobSince: null,
        executiveSince : null
      }
    };
  },

  methods: {
    checkIfCityValid(ZipPlusCity) {
      // Example for ZipPlusCity: 90449 - Nürnberg
      let plzFound;
      let cityFound;
      // plzFound undefined if plz : ZipPlusCity ' + ZipPlusCity)
      if (ZipPlusCity == null || ZipPlusCity == '') {
        return false;
      }
      //return false if seperator is missing
      if (ZipPlusCity.search(' - ') == -1 ) {
        return false;
      }
      // Check if zip code ist valid
      const activePlz = ZipPlusCity.split(' - ')[0];
      plzFound = this.cityList.find(city => city.plz === activePlz);
      // Check if city name is valid
      const activeCityName = ZipPlusCity.split(' - ')[1];
      cityFound = this.cityList.find(city => city.stadt === activeCityName);
      if (plzFound === undefined || cityFound === undefined) {
        
        return false;
      }
      return true;
    },

    checkIfCityEmpty(ZipPlusCity) {
      if (ZipPlusCity == '' || ZipPlusCity == null) {
        return true
      } else {
        return false
      }
    },

    resetCity() {
      this.companyZipAndCity = ''
      this.companydata.company_zip.fullName = ''
    },

    resetBillingCity() {
      this.billingZipAndCity = ''
      this.companydata.billing_zip.fullName = ''
    },

    setDirty() {
      this.isDirty = true;
    },

    setCityData() {
      this.companydata.plz = this.$city.getObjectByFullName(this.companydata.plz.fullName);
    },

    activateDeleteBlock () {
      this.isDelBlockActive = true
    },

    disableActiveDeleteBlock () {
      this.isDelBlockActive = false
    },

    updateCompanyData() {
      //Only execute update if anything has changed
      if (!this.isDirty) {
        return;
      }
      //check if zip-code - city combination is valid for company and billing address
      this.companydata.company_zip.isValid = this.checkIfCityValid(this.companyZipAndCity);
      this.companydata.billing_zip.isValid = this.checkIfCityValid(this.billingZipAndCity);
      //check if zip-code - city field ist empty
      this.companydata.company_zip.isEmpty = this.checkIfCityEmpty(this.companyZipAndCity);
      this.companydata.billing_zip.isEmpty = this.checkIfCityEmpty(this.billingZipAndCity)
      //if zip-city-combination is empty -> null
      //for company address
      if(this.companydata.company_zip.isEmpty == true) {
        this.companydata.company_zip.id = null;
        this.companydata.company_zip.fullName = null
      };
      //for billing address
      if(this.companydata.billing_zip.isEmpty == true) {
        this.companydata.billing_zip.id = null;
        this.companydata.billing_zip.fullName = null
      };
      //Only execute update if zip code city combinations are valid or empty else leave
      //company
      if (!this.companydata.company_zip.isValid && !this.companydata.company_zip.isEmpty) {
        return;
      }
      //billing
      if (!this.companydata.billing_zip.isValid && !this.companydata.billing_zip.isEmpty) {
        return;
      }
      //if zip-city-combination is not empty -> get value from field
      //for company zip-code
      if(this.companydata.company_zip.isEmpty == false) {
        this.companydata.company_zip.fullName = this.companyZipAndCity;
        this.companydata.company_zip = this.$city.getObjectByFullName(this.companydata.company_zip.fullName)
      };
      //for billing zip-code
      if(this.companydata.billing_zip.isEmpty == false) {
        this.companydata.billing_zip.fullName = this.billingZipAndCity;
        this.companydata.billing_zip = this.$city.getObjectByFullName(this.companydata.billing_zip.fullName)
      };

      // Update company data
      this.$company.updateCompany(this.companydata)
        .then(resp => {
          window.scrollTo(0,0);
        });
    },

    delCurrentCompany() {
      if(this.companydata.deleteActiveCompany == "JA") {
        awsCognitoDeleteCompany()
          .then(this.$requestHandling.post(this.$router.app.backend_url + 'user/delUser/'))
          .then(localStorage.removeItem("user"))
          .then(localStorage.removeItem("company"))
          .then(localStorage.removeItem("userType"))
          .then(localStorage.removeItem("cognitoToken"))
          .then(this.isLoggedIn = false)
          .then(this.$router.app.loggedIn = false)
          .then(this.$router.push({
              name: "home",
              params: { successMessage: "Die Firma wurde erfolgreich gelöscht" }
          }));
                 
      } else {
        
        this.companydata.deleteActiveCompany = ''

      };

    },

    keepCurrentCompany() {

      this.companydata.deleteActiveCompany = '';
      this.disableActiveDeleteBlock()

    },
    
    getCityList() {
      this.$city.getCityList()
      .then(resp => {
        this.cityList = resp;
      })
    },

    getCompanyData(company_id) {
      this.$company.getCompanyData(company_id)
      .then(data => {
        this.companydata = data;
        if (data.plz_id !== null) {
          this.companyZipAndCity = data.company_zip.fullName
        } else {
          this.companyZipAndCity = ""
        }
        if (data.billing_plz_id !== null) {
          this.billingZipAndCity = data.billing_zip.fullName
        } else {
          this.billingZipAndCity = ""
        }
      });
    },

    changePw() {

      if(this.companydata.newPw == this.companydata.newPwConfirm){

        awsCognitoChangeCompanyPw(this.companydata.email, this.companydata.company_oldPw, this.companydata.company_newPw)
        console.log(this.companydata.oldPw)

      } else {

      };

    },


  },

  created() {
    // Get companydata by JWT
    this.getCompanyData(this.$router.app.company_jwtHandler.getCompanyIdByJWT());
    this.getCityList();
  },

};
</script>
