<template lang="html">
<div class="container text-center">
	   <h1>Herzlich Willkommen bei Jinder</h1>
</div>
</template>


<script>
import axios from 'axios';
export default{
	props:['id'],
	data() {
		return{
			password: null,
		}
	},
	methods:{
	},
	created(){
	},
  watch: {
    '$route'() {
    }
  }
}
</script>