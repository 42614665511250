import axios from 'axios';

export default class jwt {


	constructor(router) {
		this.userid=null;
		this.router = router;
	}

	getIdByJWT() {
	  let jsonPayload = this.getUserData();
	  if(jsonPayload) {
      this.userid = JSON.parse(jsonPayload)['username'];
    }
		return this.userid;
	}
  
  getUserData() {

    let user = JSON.parse(localStorage.getItem("user"));
    let data = null;
    if(user) {
      var base64Url = user.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      data = jsonPayload;
    }
	  return data;
  }
}
