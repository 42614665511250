import axios from 'axios';

export default class company_jwt {


	constructor(router) {
		this.companyid=null;
		this.router = router;
	}

	getCompanyIdByJWT() {
	  let jsonPayload = this.getCompanyData();
	  if(jsonPayload) {
      this.companyid = JSON.parse(jsonPayload)['companyname'];
    }
		return this.companyid;
	}
  
  getCompanyData() {
    let company = JSON.parse(localStorage.getItem("company"));
    let data = null;
    if(company) {
      var base64Url = company.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      data = jsonPayload;
    }
	  return data;
  }
}
