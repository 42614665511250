<template lang="html">
  <div class="container text-center">
    <h1 class="center">Auswertung</h1>
    <template>
      <b-card
        no-body
        class="mb-4"
        v-for="(item, index) in groupdQuestions"
        v-bind:key="item.id"
      >
        <b-card-header header-tag="header" class="card-header">
          <div class="d-flex flex-row justify-content-between">
            <div>{{ groupdQuestions[index].nameLeft }}</div>
            <div>vs.</div>
            <div>{{ groupdQuestions[index].nameRight }}</div>
          </div>
        </b-card-header>
        <b-card-body class="card-body">
          <div class="row mt-2">
            <div class="col col-centered">
              <vue-slider
                :value="calcAvgCatResult(index)"
                :tooltip="'none'"
                :marks="marks1"
                :process="false"
                :dot-style="{ backgroundColor: '#36abff' }"
                :disabled="true"
                :dotSize="20"
                :id="`slider-input'${index}`"
              ></vue-slider>
            </div>
          </div>

          <div class="row mt-2"></div>
        </b-card-body>
      </b-card>
    </template>

    <div class="text-center">
      <br />
      <router-link class="btn btn-outline-primary" to="/signedin"
        >Zurück</router-link
      >
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  props: ["id"],
  data() {
    return {
      questionid: 0,
      key: 0,
      groupdQuestions: null,
      // value: [5,10], // for multipe dots
      value: 0, //für einen Dot
      userid: null,

      marks1: {
        1: "stark",
        25: "eher",
        50: "neutral",
        75: "eher",
        100: "stark",
      },
    };
  },
  methods: {
    reset(key) {
      this.questionid = 0;
      this.key = key;
    },

    getItems() {
      this.$user
        .getGroupedQuestionsForEvaluation(this.userid)
        .then((response) => {
          this.groupdQuestions = response;
        });
    },

    // catIndex is the index of the category, for each category calculate sum up the valid values
    calcAvgCatResult(catIndex) {
      let maxValue = this.groupdQuestions[catIndex].data[0].maxValue;

      let res = 0;
      let isInverted = false;
      let currentValue = 0;
      let sumValue = 0;
      let countValidValues = 0;

      for (let i = 0; i < maxValue; i++) {
        isInverted = this.groupdQuestions[catIndex].data[i].itm_isinverted;

        // check if is inverted, if yes 100 - value
        if (isInverted == true) {
          currentValue =
            100 - this.groupdQuestions[catIndex].data[i].user_items[0].value;
        } else {
          currentValue =
            this.groupdQuestions[catIndex].data[i].user_items[0].value;
        }

        if (currentValue > 0) {
          sumValue = sumValue + currentValue;
          countValidValues++;
        }
      }

      res = Math.round(sumValue / (1 + countValidValues));

      // console.log("");
      // console.log("-------------");
      // console.log("CatIndex " + catIndex);
      // console.log("Summe " + sumValue);
      // console.log("Teiler " + countValidValues);
      // console.log("Ergebnis " + res);
      // console.log("-------------");

      return res;
    },

    updateQuestionValue(item) {
      this.$item.updateItem(item, this.userid);
    },
  },

  created() {
    this.userid = this.$router.app.jwtHandler.getIdByJWT();
    this.getItems();
  },

  components: {
    VueSlider,
  },

  watch: {
    $route() {},
  },
};
</script>

<style scoped></style>
