<template>
  <div class="container">
    <div class="row align-items-center mt-3">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <strong>Allgemeine Geschäftsbedingungen</strong>
          </div>
          <div class="card-body">
            <p>
				<strong>
					Allgemeine Geschäftsbedingungen für die Nutzung von
					<router-link to="/home">www.dein-idealer-job.de</router-link> in
					Deutschland
			  </strong>
            </p>

            <p>Stand: 06.02.2022</p>

            <p>
              Die folgenden Geschäftsbedingungen regeln das unentgeltliche
              Vertragsverhältnis zwischen
              <router-link to="/home">www.dein-idealer-job.de</router-link>
              (Vertragspartner ist die aquadrat – Dr Meyer, Krombholz, Heinze,
              Leuchtenstern, Romer Gbr Pfaffenschlag 10, 96114 Hirschaid) und
              seinen Kunden.
            </p>

            <p>
				<strong>
					1. Gegenstand des Vertrags
				</strong>
			</p>

            <p>
              1.1
              <router-link to="/home">www.dein-idealer-job.de</router-link>
              bietet seinen Kunden während der Vertragslaufzeit den Zugriff auf
              eine Online-Datenbank, über die sie sich mit ihren Werten und
              Eigenschaften Unternehmen vorstellen können. Dieser Service ist
              ausschließlich über folgende Webseiten abrufbar:
            </p>

			<p>
				<ul>
					<li>
						<router-link to="/home">www.dein-idealer-job.de</router-link>
					</li>
					<li>
						<router-link to="/home">www.dein-idealer-job.com</router-link>
					</li>
				</ul>
			</p>

			<p>Der Service ist für Endkunden kostenlos</p>

			<p>1.2 Die von <router-link to="/home">www.dein-idealer-job.de</router-link> zur Verfügung gestellte Online-Datenbank enthält sogenannte Profile, die von den angemeldeten Kunden mit eigenen Inhalten (auch als „Daten“ bezeichnet) versehen werden. Welche Daten in diesem Zusammenhang erhoben werden und wie diese Daten anderen Kunden gegenüber sichtbar sind, ergibt sich auch aus den gesonderten Datenschutzhinweisen.</p>

			<p><strong>2 Zugang/Vertragsschluss</strong></p>

			<p>2.1 Der Zugang zur Nutzung von <router-link to="/home">www.dein-idealer-job.de</router-link> setzt die Anmeldung voraus. Hierfür benötigt der Kunde eine E-Mail-Adresse und ein von ihm selbst gewähltes Passwort.</p>

			<p>2.2 Mit der Anmeldung entsteht zunächst ein grundsätzlich kostenloses Vertragsverhältnis zwischen <router-link to="/home">www.dein-idealer-job.de</router-link> und dem angemeldeten Kunden.</p>

			<p>2.3 Um <router-link to="/home">www.dein-idealer-job.de</router-link> zu nutzen, ist es erforderlich, das Profil komplett auszufüllen. Dies beinhaltet auch Daten wie E-Mailadresse, Wohnort, Gehaltswunsch etc. Erst nach Vorliegen aller Angaben ist das Profil für Firmen sichtbar.</p>

			<p><strong>3. Leistungen</strong></p>

			<p>3.1 <router-link to="/home">www.dein-idealer-job.de</router-link> bietet im ersten Schritt eine kostenlose Registrierung. Im Anschluss daran kann der Kunde ein vollständiges Profil seiner beruflichen Werte und Ziele anhand eines Fragebogens erstellen. Zusätzlich können persönliche Daten hinterlegt werden. Wenn das Profil vollständig ausgefüllt ist, werden die Daten des Kunden mit den offenen Stellenanzeigen aller im System befindlichen Unternehmen verglichen. Die Unternehmen erhalten eine anonymisierte Auswertung welcher Kunde am besten zu ihnen passt. Die Unternehmen haben dann die Möglichkeit ihr Interesse an einem Gespräch mit den einzelnen Kunden anzumelden. <router-link to="/home">www.dein-idealer-job.de</router-link> wird dieses Interesse im System weitergeben und der Kunde kann darauf eingehen oder auch nicht. Hier endet das Dienstleistungsverhältnis von <router-link to="/home">www.dein-idealer-job.de</router-link>.</p>

			<p>3.2 <router-link to="/home">www.dein-idealer-job.de</router-link> strebt an, seinen Service durchgehend 24 Stunden, sieben Tage die Woche einsatzfähig mit einer Verfügbarkeit von 99,5 % im Jahresmittel zur Verfügung zu stellen. Hiervon ausgenommen sind Ausfallzeiten durch Wartung und Software-Updates sowie Zeiten, in denen der Dienst aufgrund von technischen oder sonstigen Problemen, die nicht im Einflussbereich von <router-link to="/home">www.dein-idealer-job.de</router-link> liegen (höhere Gewalt, Verschulden Dritter etc.), über das Internet nicht zu erreichen ist. Um <router-link to="/home">www.dein-idealer-job.de</router-link> in vollem Umfang nutzen zu können, muss der Kunde jeweils die neuesten (Browser-) Technologien verwenden oder deren Verwendung auf seinem Computer oder Smartphone ermöglichen (z.B. Aktivierung von Java Skript, Cookies, Pop-ups). Bei Benutzung älterer oder nicht allgemein gebräuchlicher Technologien kann es sein, dass der Kunde die Leistungen von <router-link to="/home">www.dein-idealer-job.de</router-link> nur eingeschränkt nutzen kann.</p>

			<p><strong>4.Kündigung</strong></p>

			<p>Der Kunde hat jederzeit das Recht, den Vertrag über die kostenlose Basis-Mitgliedschaft mit sofortiger Wirkung zu kündigen.</p>

			<p><strong>5. Haftung</strong></p>

			<p>5.1 <router-link to="/home">www.dein-idealer-job.de</router-link> kann nicht für falsche Angaben in den Anmeldungen und/oder Ausfüllung der Daten der Kunden verantwortlich gemacht werden. Hieraus folgt, dass <router-link to="/home">www.dein-idealer-job.de</router-link> keine Haftung für die Richtigkeit der Ergebnisse des Fragebogens sowie die Inhalte des auf dessen Grundlage erstellten Portraits des Kunden übernehmen kann.</p>

			<p>5.2 <router-link to="/home">www.dein-idealer-job.de</router-link> schuldet lediglich die Bereitstellung der IT-Dienstleistungen zur automatischen Vermittlung von Kontakten, nicht den Erfolg. <router-link to="/home">www.dein-idealer-job.de</router-link> stellt nur die technische Vorrichtung bereit, generell eine Kontaktaufnahme zwischen Kunde und verschiedenen Firmen zu ermöglichen.</p>

			<p>5.3 <router-link to="/home">www.dein-idealer-job.de</router-link> übernimmt überdies keine Haftung für den eventuellen Missbrauch von Informationen. Es ist möglich, dass Kunden <router-link to="/home">www.dein-idealer-job.de</router-link> trotz Verbot in unzulässiger oder gesetzwidriger Weise nutzen. Für eine solche unzulässige oder gesetzwidrige Nutzung ist jede Haftung von <router-link to="/home">www.dein-idealer-job.de</router-link> ausgeschlossen. <router-link to="/home">www.dein-idealer-job.de</router-link> haftet auch nicht dafür, dass Angaben und Informationen, die die Kunden selbst Dritten zugänglich gemacht haben, von diesen missbraucht werden.</p>

			<p>5.4 <router-link to="/home">www.dein-idealer-job.de</router-link> gewährleistet nicht den jederzeitigen ordnungsgemäßen Betrieb bzw. die ununterbrochene Nutzbarkeit bzw. Erreichbarkeit des Service. Insbesondere haftet <router-link to="/home">www.dein-idealer-job.de</router-link> nicht für Störungen der Qualität des Zugangs zum Service aufgrund höherer Gewalt oder aufgrund von Ereignissen, die <router-link to="/home">www.dein-idealer-job.de</router-link> nicht zu vertreten hat. Ferner haftet <router-link to="/home">www.dein-idealer-job.de</router-link> nicht für die unbefugte Kenntniserlangung Dritter von persönlichen Daten von Kunden (z.B. durch einen unbefugten Zugriff von "Hackern" auf die Datenbank).</p>

			<p><strong>6. Verantwortlichkeit und Pflichten des Kunden</strong></p>

			<p>6.1 Der Kunde ist für den Inhalt seiner Anmeldung und damit für die Informationen, die er über sich bereitstellt, allein verantwortlich. Der Kunde versichert, dass die angegebenen Daten der Wahrheit entsprechen und ihn persönlich beschreiben. Vorsätzlich und/oder in betrügerischer Absicht gemachte Falschangaben oder das Vorspiegeln einer anderen oder erdachten Identität können auch zivilrechtliche Schritte nach sich ziehen.</p>

			<p>6.2 Der Kunde verpflichtet sich, <router-link to="/home">www.dein-idealer-job.de</router-link> schadlos von jeglicher Art von Klagen, Schäden, Verlusten oder Forderungen zu halten, die durch seine Anmeldung und/oder Teilnahme bei diesem Service entstehen könnten, sofern der Kunde schuldhaft gehandelt hat. Dies gilt insbesondere für Schäden wegen übler Nachrede, Beleidigung, Verletzung von Persönlichkeitsrechten, wegen des Ausfalls von Dienstleistungen für andere Kunden, einem Verstoß gegen diese Geschäftsbedingungen, wegen der Verletzung von Immaterialgütern oder sonstiger Rechte. Der Aufwendungsersatzanspruch ist der Höhe nach auf die notwendigen bzw. die zu einem bestimmten Zweck erforderlichen Aufwendungen beschränkt.</p>

			<p>6.3 Der Kunde ist verpflichtet, E-Mails und andere Nachrichten vertraulich zu behandeln und diese nicht ohne Zustimmung ihres Urhebers bzw. ohne wichtigen Grund Dritten zugänglich zu machen. Gleiches gilt für Namen, Telefon- und Faxnummern, Wohn-, E-Mail-Adressen und/oder URLs bzw. sonstige personenbezogene Daten mit dem Kunden interagierender Firmen.</p>

			<p>6.4 Der Kunde erklärt sich bereit, <router-link to="/home">www.dein-idealer-job.de</router-link> umgehend mitzuteilen, wenn er wegen des Zustandekommens eines Arbeitsverhältnisses oder aus sonstigen Gründen an Matches nicht mehr interessiert ist oder wenn er Firmen nicht mehr als Match zur Verfügung stehen will.</p>

			<p>6.5 Die Nichtbeachtung einer der oben genannten Verhaltensverpflichtungen kann zu folgenden Sanktionen führen: Aufforderung zur Stellungnahme im Falle des Verdachts von Vertrags- / Gesetzesverstößen, Sperrung der Nutzung bis zur Aufklärung von Sachverhalten (insbesondere bei mehreren Beschwerden von beteiligten Firmen), Verwarnung eines Kunden, Ein- /Beschränkung bei der Nutzung von Anwendungen im Job-Service, teilweises Löschen von Inhalten.</p>

			<p><strong>7. Kommunikation</strong></p>

			<p><router-link to="/home">www.dein-idealer-job.de</router-link> ist ein Online-Dienst. Als solcher kommuniziert <router-link to="/home">www.dein-idealer-job.de</router-link> mit seinen Kunden entweder über die Bildschirmmaske im eingeloggten Zustand oder per E-Mail. Telefon-, Fax- und Post-Kommunikation wird ermöglicht, sofern es sich um Fragestellungen des Vertragsschlusses, oder der verschlüsselten E-Mailkommunikation handelt. Für die Erbringung der Hauptleistungen (das Matching zwischen Job-Interessenten und Firmen) sowie den Vertragsschluss selbst ist die Telefon-, Fax- und Post-Kommunikation ausgeschlossen.</p>

			<p><strong>8 Urheberrechte</strong></p>

			<p><router-link to="/home">www.dein-idealer-job.de</router-link> ist im Verhältnis zum Kunden alleiniger Rechtsinhaber der Vervielfältigungs-, Verbreitungs-, Verarbeitungs- und sämtlicher Urheberrechte sowie des Rechts der unkörperlichen Übertragung und Wiedergabe auf <router-link to="/home">www.dein-idealer-job.de</router-link> sowie der einzelnen in ihr enthaltenen Inhalte. Die Nutzung sämtlicher Programme und der darin enthaltenen Inhalte, Materialien sowie Marken- und Handelsnamen ist ausschließlich zu den in diesen Geschäftsbedingungen genannten Zwecken zulässig.</p>

			<p><strong>Anwendbares Recht, Alternative Streitbeilegung</strong></p>

			<p>9.1 Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss deutschen Kollisionsrechts. Ist der Kunde Verbraucher mit Wohnsitz in der Europäischen Union, kann ggf. auch das Recht desjenigen Landes, in dem er seinen Wohnsitz hat, zur Anwendung kommen, sofern es sich um zwingende Bestimmungen handelt.</p>

			<p>9.2 Die EU-Kommission bietet die Möglichkeit zur Online-Streitbeilegung auf einer von ihr betriebenen Online-Plattform. Diese Plattform ist über den externen Link <a href=http://ec.europa.eu/consumers/odr/>http://ec.europa.eu/consumers/odr/</a> zu erreichen. <router-link to="/home">www.dein-idealer-job.de</router-link> nimmt nicht an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teil. Eine Rechtspflicht besteht insofern nicht.</p>

			<p><strong>10. Widerrufsbelehrung</strong></p>

			<p><strong>10.1 Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.</strong></p>

			<p><strong>Die Widerrufsfrist beträgt 14 Tage ab dem Tag des Vertragsschlusses.</strong></p>

			<p><strong>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, der </strong></p>

			<p>
				aquadrat – Dr Meyer, Krombholz, Heinze, Leuchtenstern, Romer GbR <br>
				Pfaffenschlag 10 <br>
				96114 Hirschaid <br>
				Telefon: + 49 (0) 911 92 86 76 52 <br>
				Telefax: + 49 (0) 911 92 86 76 53 <br>
				<a href="mailto:florian@leuchtenstern.com?subject=Widerruf">Florian@Leuchtenstern.com</a><br>
			</p>

			<p><strong>mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können folgendes Musterformular für ihren Widerruf verwenden 
				<a href="../public/assets/Widerruf.pdf" download>(Link)</a>.</strong></p>

			<p><strong>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</strong></p>

			<p><strong>10.2 Folgen des Widerrufs</strong></p>

			<p>Der Vertrag erlischt mit Absendung des Widerrufs. Sobald Ihr Schreiben eingegangen ist, werden wir Ihre Daten unwiderruflich aus dem System löschen.</p>

			<p>Kosten entstehen Ihnen dafür keine.</p>
			
			<p><strong>11. Sonstiges</strong></p>

			<p>11.1 <router-link to="/home">www.dein-idealer-job.de</router-link> ist berechtigt, dritte Dienstleister und Erfüllungsgehilfen mit der Erbringung von Teilen oder des ganzen Leistungsspektrums zu beauftragen, sofern für den Kunden hierdurch keine Nachteile entstehen.</p>

			<p>11.2 Sollte eine Bestimmung des Vertrags oder der AGB unwirksam sein oder werden oder sollte der Vertrag unvollständig sein, so wird der Vertrag in seinem übrigen Inhalt davon nicht berührt. Ergänzend gelten die gesetzlichen Bestimmungen.</p>

			<p>11.3 Die Vertragssprache ist deutsch.</p>

			<p>11.4 Im Rahmen des Vertragsschlusses erfolgt keine automatische Speicherung des Vertragstextes durch <router-link to="/home">www.dein-idealer-job.de</router-link>. Der Kunde ist deshalb angehalten, den zum Zeitpunkt des Vertragsschlusses jeweils gültigen, auf der Webseite von <router-link to="/home">www.dein-idealer-job.de</router-link> zugänglichen Vertragstext (AGB) auf seiner Festplatte zu speichern oder auf andere Weise zu archivieren.</p>

			<p>11.5 Verantwortlich für den Inhalt des Service ist die aquadrat – Dr Meyer, Krombholz, Heinze, Leuchtenstern, Romer GbR, Pfaffenschlag 10, 96114 Hirschaid, diese vertreten durch Florian Leuchtenstern.</p>
				

          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-3">&nbsp;</div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      password: null,
    };
  },
  methods: {},
};
</script>
