import Vue from 'vue'
import axios from 'axios'
import requestHandler from '../api/RequestHandling'
import { awsCognitoRegisterUser } from "../api/awsCognito"
var md5 = require("md5");


let user = new Vue({
  data() {
    return {
      userdata: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        telephone: null,
        street: null,
        graduation: null,
        job:{
          id:null,
          name: null
        },
        jobSince: null,
        executiveSince : null,
        city: {
          id: null,
          plz: null,
          name: null,
          fullName: null
        }
      },
      awsCognito: {
        userPoolId: null,
        clientId: null,
        region: null,
        identityPoolId: null,
      },
      expectedJobList : {
        id: null,
        job: {
          id: null,
          category: null,
          group: null,
          name: null,
          weg: null,
        },
        plz: {
          id: null,
          plz: null,
          stadt: null
        },
        salary: null,
        perimeter: null
      },
      groupedItems: null

    }
  },
  components: {
    requestHandler
  },
  methods: {
    
    async registerUser(mail,pw,agbconfirmed) {
      
      let resp;

      //set dummy-pw for legacy db md5 not required anymore
      let param = {
        email: mail,
        password: process.env.DUMMY_PW_ENV,
        agbsconfirmed: agbconfirmed
      }
      await this.$requestHandling.post(this.$requestHandling.backend_url+'login/signup', param)
        .then(response => {

          awsCognitoRegisterUser(mail, pw);       

          resp = response;
        })
        .catch(error => {
          console.log("----ERROR---");
          console.log(error);
        })
      return resp;
      
    },

    async getUserData(userid) {
      let resp;
      if(this.userdata.firstname == null) {
        await this.$requestHandling.get(this.$requestHandling.backend_url+'user/'+userid, {userid: userid}).then(response => {
          this.userdata = response;
          if(response.plz == null) {
            this.userdata.plz = {
              plz: null,
              stadt: null,
              detailed: null
            };
          } else {
            this.userdata.plz.detailed = response.plz.plz + " - " + response.plz.stadt;
          }
          if(response.job == null) {
            this.userdata.job = {
              id: null,
              name: null
            }
          }
          if(response.graduation == null) {
            this.userdata.graduation = {
              name: null
            }
          }

        });
      }
      return this.userdata;
    },
    
    async updateUser(userObj) {
      
      let param = {
        id: userObj.userid,
        firstname: userObj.firstname,
        lastname: userObj.lastname,
        tel: userObj.telephone,
        street: userObj.street,
        plz_id: userObj.plz.id,
        work_experience: userObj.jobSince,
        executive_experience: userObj.executiveSince
      };

      await this.$requestHandling.put(this.$requestHandling.backend_url+'user/'+userObj.id, param)
        .then(resp => {
          this.$messageHandling.handleSuccess("Deine Daten wurden erfolgreich gespeichert!");
        })

      return "";
    },
    async updateUserJob(jobId, graduationId, userid) {
      let param = {
        graduation_id: graduationId,
        job_id: jobId
      }
      await this.$requestHandling.put(this.$requestHandling.backend_url+'user/' + userid, param)
        .then(response => {
          this.$messageHandling.handleSuccess("Deine Daten wurden erfolgreich gespeichert!");
        })
    },

    async getExpectedJobList(userid) {
      await this.$requestHandling.get(this.$requestHandling.backend_url+'job/expectedJob/'+userid, {})
        .then(response => {
          response.forEach(function(item, index) {
            response[index].salary = new Intl.NumberFormat('de-DE', { style: 'decimal'}).format(response[index].salary);
            response[index].plz.detailed = response[index].plz.plz + " - " + response[index].plz.stadt;
            response[index].headline = response[index].job.name + " in " + response[index].plz.stadt;
            response[index].accordionToggleId="v-b-toggle.accordion-"+response[index].id;
            response[index].accordionId= "accordion-"+response[index].id;
          })
          this.expectedJobList = response;
        })
      return this.expectedJobList;
    },
    
    async updateExpectedJob(param){
      
      if (param.salary != undefined) {
      param.salary = param.salary.replace(/\./g, '');
      }

      let resp;
      
      await this.$requestHandling.post(this.$requestHandling.backend_url+'job/saveExpectedJob', param)
        .then(response => {
          resp = response;
          this.$messageHandling.handleSuccess("Der Job wurde erfolgreich gespeichert");
        })
      return resp;
    },

    async deleteExpectedJob(param){

      let resp;
      await this.$requestHandling.post(this.$requestHandling.backend_url+'job/deleteExpectedJob', param)
        .then(response => {
          resp = response;
          this.$messageHandling.handleSuccess("Der Job wurde erfolgreich gelöscht!");
        })
      return resp;
    },
    
    async getGroupedQuestions(userid) {
      // console.log(this.$requestHandling.backend_url+'item/groupedItems/' + userid)
      await this.$requestHandling.get(this.$requestHandling.backend_url+'item/groupedItems/' + userid, {})
        .then(response => {
          response.forEach(element => {
            let maxValue = element.data.length * 1;
            element.data.forEach((subelement, key) => {
              subelement.maxValue = maxValue;
              subelement.currentValue = (key);
              subelement.buttonDescription ="Speichern und weiter";
              subelement.buttonDisabled = false;

              if(subelement.user_items.length < 1) {
                subelement.user_items.push({
                  value:0
                })
              }
            })
          })
          this.groupedItems = response;
        })
      return this.groupedItems;
    }
  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$user = user;
  }
}
