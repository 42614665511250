import Vue from 'vue'
import axios from 'axios'
import requestHandler from '../api/RequestHandling'


let item = new Vue({
  data() {
    return {
      item: null
    }
  },
  components: {
    requestHandler
  },
  methods: {
    async updateItem(item, userid) {
      let resp;
      let param = {
        userId: userid,
        value: item.user_items[0].value,
        itemId: item.itm_id
      }
      await this.$requestHandling.post(this.$requestHandling.backend_url+'user_item/addUserItem', param)
        .then(response => {

        });
    }
  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$item = item;
  }
}
