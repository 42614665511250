<template lang="html">

<div class="container">
	<div class="row align-items-center mt-3">
		<div class="col-sm-12">
			<div class="card">
				<div class="card-header" >
					Kontakt
				</div>
				<div class="card-body" >
					<div class="row">
						   <div class="col-sm-4" id="contact2">

							<hr align="left" width="50%">
							<h4 class="pt-2">Aquadrat GbR</h4>
							<b-icon-globe2></b-icon-globe2>Pfaffenschlag 10, 96114 Hirschaid<br>
							<h4 class="pt-2">Kontakt</h4>
							<b-icon-telephone-fill></b-icon-telephone-fill><a href="tel:+4991192867652"> + 49 (0) 911 92 86 76 52 </a><br>
							<h4 class="pt-2">Email</h4>
							<b-icon-envelope-fill></b-icon-envelope-fill> <a href="">Florian@Leuchtenstern.com</a><br>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row align-items-center mt-3">
		&nbsp;
	</div>
	</div>
</template>

<script>
import axios from 'axios';
export default{
	props:['id'],
	data() {
		return{
			password: null,
		}
	},
	methods:{
	}
}
</script>
