import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt from './api/jwt'
import company_jwt from './api/company_jwt'
import App from './App.vue'
import Post from './components/Post.vue'
import Hello from './components/Hello.vue'
import Impressum from './components/Impressum.vue'
import Information from './components/Information.vue'
import Agb from './components/Agb.vue'
import Datenschutz from './components/Datenschutz.vue'
import Disclaimer from './components/Disclaimer.vue'
import Contact from './components/Contact.vue'
import Signin from './components/Signin.vue'
import Signup from './components/Signup.vue'
import SignedIn from './components/SignedIn.vue'
import CompanyMyData from './components/CompanyMyData.vue'
import CompanySignup from './components/CompanySignup.vue'
import CompanySignin from './components/CompanySignin.vue'
import CompanySignedIn from './components/CompanySignedIn.vue'
import Mydata from './components/Mydata.vue'
import Myquestion from './components/Myquestion.vue'
import MyJobData from './components/MyJobData.vue'
import Loggedin from './components/LoggedIn.vue'
import Callback from './components/Callback.vue'
import MyExpectedJob from "./components/MyExpectedJob";
import Evaluation from "./components/Evaluation";
import GAuth from 'vue-google-oauth2'
import auth from './api/auth';
import company_auth from "./api/company_auth";
import messageHandling from './api/MessageHandling';
import requestHandling from './api/RequestHandling';
import jobs from './controller/job';
import graduation from './controller/graduation';
import user from './controller/user';
import company from './controller/company';
import city from './controller/city';
import item from './controller/item';
import axios from 'axios'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueLoading from 'vuejs-loading-plugin'
import { IconsPlugin } from 'bootstrap-vue'
import bFormSlider from 'vue-bootstrap-slider';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';

// Import Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { all } from '@awesome.me/kit-c4dd03de42/icons';
library.add(...all);


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(auth);
Vue.use(company_auth);
Vue.use(company);
Vue.use(messageHandling);
Vue.use(requestHandling);
Vue.use(jobs);
Vue.use(graduation);
Vue.use(user);
Vue.use(city);
Vue.use(item);
Vue.use(VueLoading);
Vue.use(bFormSlider);
Vue.component('font-awesome-icon', FontAwesomeIcon);


const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/post/:id',
      name:'post',
      component: Post,
      props: true,
    },{
      path: '/',
      name:'home',
      component: Hello,
      props: true,
    },{
      path: '/companyhome',
      name:'companyhome',
      component: CompanySignedIn,
      props: true,
    },{
      path: '/companymydata',
      name:'companymydata',
      component: CompanyMyData,
      props: true,
    },{
      path: '/information',
      name:'information',
      component: Information,
      props: false,
    },{
      path: '/agb',
      name:'agb',
      component: Agb,
      props: false,
    },{
      path: '/disclaimer',
      name:'disclaimer',
      component: Disclaimer,
      props: false,
    },{
      path: '/impressum',
      name:'impressum',
      component: Impressum,
      props: false,
    },{
      path: '/datenschutz',
      name:'datenschutz',
      component: Datenschutz,
      props: false,
    },{
      path: '/contact',
      name:'contact',
      component: Contact,
      props: false,
    },{
      path: '/',
      name:'loggedin',
      component: Loggedin,
      props: true,
    },{
      path: '/',
      name:'callback',
      component: Callback,
      props: false,
    },{
      path: '/mydata',
      name:'mydata',
      component: Mydata,
      props: true,
    }
    ,{
      path: '/signin',
      name:'signin',
      component: Signin,
    },{
      path: '/signup',
      name:'signup',
      component: Signup,
    },{
      path: '/signedin',
      name:'signedin',
      component: SignedIn,
    },{
      path: '/companySignup',
      name:'companySignup',
      component: CompanySignup,      
    },{
      path: '/companySignin',
      name:'companySignin',
      component: CompanySignin,            
    },{    
      path: '/myquestion',
      name:'myquestion',
      component: Myquestion,
    },{
      path: '/myjobdata',
      name:'myjobdata',
      component: MyJobData,
      props: true,
    },{
      path: '/expectedjob',
      name:'expectedjob',
      component: MyExpectedJob,
      props: true,
    },{
      path: '/evaluation',
      name: 'evaluation',
      component: Evaluation,
      props: true
    }
  ]
});

// React on called routes

router.beforeEach((to, from, next) => {
  if(to.name == 'home' || to.name == 'information' || to.name == 'impressum' || to.name == 'agb' || to.name == 'contact' || to.name == 'datenschutz' || to.name == 'disclaimer') { // check if "to"-route is "callback" and allow access
    next()
  } else if (to.name == 'signin' || to.name == 'signup') { // if authenticated allow access
    if(router.app.$auth.isAuthenticated()) {
      router.push({name: 'signedin', params: {successMessage: "Du hast dich erfolgreich angemeldet"}});
    } else {
      next();
    }
  } else if (to.name == 'companySignin' || to.name == 'companySignup') { // if authenticated allow access
    if(router.app.$company_auth.isCompanyAuthenticated()) {
      router.push({name: 'companyhome', params: {successMessage: "Du hast dich erfolgreich im Firmenbereich angemeldet"}});
    } else {
      next();
    }

  } else if (to.name == 'signedin') { // if authenticated as user allow access
    if(router.app.$auth.isAuthenticated()) {
      next();
    } else {
      router.push({name: 'home'});
    }   

  } else if (to.name == 'companyhome' || to.name == 'companymydata') { // if authenticated allow access
    if(router.app.$company_auth.isCompanyAuthenticated()) {
      next();
    } else {
      router.push({name: 'home', params: {errorMessage: "Du bist erfolgreich abgemeldet"}});
    }    
    
  } else if (router.app.$auth.isAuthenticated()) { // if authenticated allow access
    next();
  } else if(to.name == 'callback') {
    setTimeout(router.go, 200);
    router.push({name: 'home', params: {successMessage: "Du hast dich erfolgreich mit Google angemeldet"}});
  }  else { // trigger auth0 login
    router.push({name: 'home', params: {errorMessage: "Du hast keine Zugriffsberechtigungen. Bitte erneut anmelden"}})
  }
})

const jwtHandler = new jwt(router);
const company_jwtHandler = new company_jwt(router);

// backend_url inluding port 
var var_backend_url;

// TARGET_ENV comes from webpack.config.js
// BACKEND_URL_... comes from vue-spa -> .env file
if (process.env.TARGET_ENV === 'development') {
      var_backend_url = process.env.BACKEND_URL_ENV_DEV;

  }

if (process.env.TARGET_ENV === 'staging') {
    var_backend_url = process.env.BACKEND_URL_ENV_STAGE;

}

if (process.env.TARGET_ENV === 'production') {
  var_backend_url = process.env.BACKEND_URL_ENV_PROD;

}

const gauthOption = {
  // clientId: '427642123675-h9e0e7g2ngnph027ceps73mm8m7sveq7.apps.googleusercontent.com',
  clientId: '427642123675-l8bu802jvtdqsu8hcu4pmg13qnckg2c2.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}

Vue.use(GAuth, gauthOption);

var app = new Vue({
  render: h => h(App),
  router,
  props: ['id'],
  data: {
    posts: null,
    endpoint: 'https://jsonplaceholder.typicode.com/posts/',
    jwtHandler: jwtHandler,
    company_jwtHandler: company_jwtHandler,
    backend_url: var_backend_url, 
    loggedIn: false
  },
  created() {
    this.getAllPosts();
  },
  methods: {
    getAllPosts() {
      axios.get(this.endpoint)
      .then(response => {
        this.posts = response.data;
      })
      .catch(error => {
        console.log("----ERROR----");
        console.log(error);
      })
    }
  }
})
.$mount('#app');


