import Vue from 'vue'
import axios from 'axios'
import requestHandler from '../api/RequestHandling'


let graduation = new Vue({
  data() {
    return {
      graduationList: null
    }
  },
  components: {
    requestHandler
  },
  methods: {
    async getGraduationList() {
      let resp;
      if(this.graduationList == null) {
        await this.$requestHandling.get(this.$requestHandling.backend_url+'graduation', {}).then(response => {
          this.graduationList = response;
        });
      }
      return this.graduationList;
    },
    getObjectByName(name) {
      if(this.graduationList == null) {
        this.getGraduationList().then(response => {
          this.getObjectByName(name);
        })
      } else {
        if(name != null) {

          for (var n = 0; n < Object.keys(this.graduationList).length; n++) {

            if(name == this.graduationList[n].name) {
              return this.graduationList[n];
            }

          }
        } else {
          return null;
        }
      }
    }
  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$graduation = graduation;
  }
}
