import Vue from 'vue'
import axios from 'axios'

let messageHandling = new Vue({
	data() {
      return {
		successMessage: null,
		showSuccessMessage : false,
		errrorMessage: null,
		showErrorMessage: false,
      }
    },
	computed: {
		
	},
  methods: {
	  handleSuccess(message) {
		this.successMessage = message;
		this.showSuccessMessage  = true;
		setTimeout(this.hideSuccessMessage, 5000);
	  },

	  hideSuccessMessage() {
		  this.showSuccessMessage = false;
	  },

	  isSuccess() {
		  return this.showSuccessMessage;
	  },

	  getSuccessMessage() {
		  return this.successMessage;
	  },
	  
	  getErrorMessage() {
		  return this.errrorMessage;
	  },

	  handleError(message) {
		  this.errrorMessage = message;
		  this.showErrorMessage = true;
		  setTimeout(this.hideErrorMessage, 5000);

	  },

	  hideErrorMessage() {
		  this.showErrorMessage = false;
	  },

	  hasError() {
		  return this.showErrorMessage;
	  },
  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$messageHandling = messageHandling
  }
}