import Vue from 'vue'
import axios from 'axios'
import requestHandler from '../api/RequestHandling'
import { awsCognitoRegisterCompany } from "../api/companyAwsCognito"
var md5 = require("md5");


let company = new Vue({
  data() {
    return {
      companydata: {
        company_id: null,
        company_name: null,
        company_department: null,
        company_contact_firstname: null,
        company_contact_lastname: null,
        company_email: null,
        company_telephone: null,
        company_street: null,
        company_zip: {
          id: null,
          zipcode: null,
          name: null,
          fullName: null
        },
        billing_department: null,
        billing_contact_firstname: null,
        billing_contact_lastname: null,
        billing_contact_email: null,
        billing_telephone: null,
        billing_street: null,
        billing_zip: {
          id: null,
          zipcode: null,
          name: null,
          fullName: null
        },
      },
      companyAwsCognito: {
        userPoolId: null,
        clientId: null,
        region: null,
        identityPoolId: null,
      },
      companyJobList : {
        id: null,
        job: {
          id: null,
          category: null,
          group: null,
          name: null,
          weg: null,
        },
        plz: {
          id: null,
          plz: null,
          stadt: null
        },
        salary: null,
        perimeter: null
      },
      companyGroupedItems: null

    }
  },
  components: {
    requestHandler
  },
  methods: {
    
    async registerCompany(mail,pw,agbconfirmed) {
      
      let resp;

      //set dummy-pw for legacy db md5 not required anymore
      let param = {
        email: mail,
        password: process.env.DUMMY_PW_ENV,
        agbsconfirmed: agbconfirmed
      }
      await this.$requestHandling.post(this.$requestHandling.backend_url+'companyLogin/companySignUp', param)
        .then(response => {

          awsCognitoRegisterCompany(mail, pw);       

          resp = response;
        })
        .catch(error => {
          console.log("----ERROR Company SignUp ---");
          console.log(error);
        })
      return resp;
      
    },

    async getCompanyData(companyId) {
      let resp;
        await this.$requestHandling.get(this.$requestHandling.backend_url+'company/'+companyId, {companidyId: companyId}).then(response => {
          this.companydata = response;
          //Get full name of company_zip
          if(response.plz_id == null) {
            this.companydata.company_zip = {
              zipcode: null,
              name: null,
              fullName: null
            };
          } else {
            this.companydata.company_zip = {
              zipcode: response.company_zip_fk.plz,
              name: response.company_zip_fk.stadt,
              fullName: response.company_zip_fk.plz + " - " + response.company_zip_fk.stadt
            };
          };
          //Get full name of billing_zip
          if(response.billing_plz_id == null) {
            this.companydata.billing_zip = {
              zipcode: null,
              name: null,
              fullName: null
            };
          } else {
            this.companydata.billing_zip = {
              zipcode: response.billing_zip_fk.plz,
              name: response.billing_zip_fk.stadt,
              fullName: response.billing_zip_fk.plz + " - " + response.billing_zip_fk.stadt
            };
          };
        });
        return this.companydata;
    },
    
    async updateCompany(companyObj) {
      
      let param = {
        id: companyObj.companyId,
        name: companyObj.name,
        email: companyObj.company_email,
        department: companyObj.department,
        contact_lastname: companyObj.company_contact_lastname,
        contact_firstname: companyObj.company_contact_firstname,
        tel: companyObj.tel,
        street: companyObj.street,
        plz_id: companyObj.company_zip.id,
        country: companyObj.country,

        billing_name: companyObj.billing_name,
        billing_contact_email: companyObj.billing_email,
        billing_department: companyObj.billing_department,
        billing_contact_lastname: companyObj.billing_contact_lastname,
        billing_contact_firstname: companyObj.billing_contact_firstname,
        billing_contact_telefon: companyObj.billing_contact_telefon,
        billing_plz_id: companyObj.billing_zip.id,
        billing_street: companyObj.billing_street,
        billing_country: companyObj.billingCountry,
        
        absconfirmed: companyObj.absconfirmed,

      };

      console.log('companyObj.company_zip.id ' + companyObj.company_zip.id);


      await this.$requestHandling.put(this.$requestHandling.backend_url+'company/'+companyObj.id, param)
        .then(resp => {
          this.$messageHandling.handleSuccess("Deine Daten wurden erfolgreich gespeichert!");
        })

      return "";
    },
    async updateCompanyJob(jobId, graduationId, companyid) {
      let param = {
        graduation_id: graduationId,
        job_id: jobId
      }
      await this.$requestHandling.put(this.$requestHandling.backend_url+'company/' + companyid, param)
        .then(response => {
          this.$messageHandling.handleSuccess("Deine Daten wurden erfolgreich gespeichert!");
        })
    },
      
    async getGroupedQuestions(companyId) {
      await this.$requestHandling.get(this.$requestHandling.backend_url+'item/groupedItems/' + companId, {})
        .then(response => {
          response.forEach(element => {
            let maxValue = element.data.length * 1;
            element.data.forEach((subelement, key) => {
              subelement.maxValue = maxValue;
              subelement.currentValue = (key);
              subelement.buttonDescription ="Speichern und weiter";
              subelement.buttonDisabled = false;

              if(subelement.company_items.length < 1) {
                subelement.company_items.push({
                  value:0
                })
              }
            })
          })
          this.groupedItems = response;
        })
      return this.groupedItems;
    }
  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$company = company;
  }
}
