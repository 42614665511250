import Vue from 'vue'
import axios from 'axios'
import requestHandler from '../api/RequestHandling'


let jobs = new Vue({
  data() {
    return {
      jobList: null
    }
  },
  components: {
    requestHandler
  },
  methods: {
    async getJobList() {
      let resp;
      if(this.jobList == null) {
        await this.$requestHandling.get(this.$requestHandling.backend_url+'job', {}).then(response => {
          this.jobList = response;
        });
      }
       return this.jobList;
    },
    getObjectByName(name) {
      if(this.jobList == null) {
        this.getJobList().then(response => {
          this.getObjectByName(name);
        })
      } else {
        if(name != null) {

          for (var n = 0; n < Object.keys(this.jobList).length; n++) {

            if(name == this.jobList[n].name) {
              return this.jobList[n];
            }

          }
        } else {
          return null;
        }
      }
    }
  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$jobs = jobs;
  }
}
