<template>
  <div class="container">
    <div class="row align-items-center mt-3">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h2>Datenschutzerklärung</h2>
          </div>
          <div class="card-body">

            <h3>Allgemein</h3>

            <p>
                Stand: 30.06.2021
            </p>

            <p><strong>Verantwortlich für die Verarbeitung personbezogener Daten:</strong><br>
            Florian Leuchtenstern<br>
            Beim Wahlbaum 73<br>
            90453 Nürnberg<br>
            Telefon: + 49 (0) 911 92 86 76 52<br>
            Email: Florian@Leuchtenstern.com</p>

            <p><strong>Datenschutzbeauftragter:</strong><br>
            Florian Leuchtenstern<br>
            Beim Wahlbaum 73<br>
            90453 Nürnberg<br>
            Telefon: + 49 (0) 911 92 86 76 52<br>
            Email: Florian@Leuchtenstern.com</p>

            <h3>1. Sinn und Zweck der Plattform</h3>

            <p>Die aQuadrat - Dr Meyer, Krombholz, Heinze, Leuchtenstern, Romer GbR bietet eine Plattform für Arbeitnehmer*innen und die Arbeitgeber*innen zum Zwecke des gegenseitigen Austauschs Unternehmens- und Person-bezogener Informationen im Hinblick auf die Anbahnung eines Angestelltenverhältnisses:</p>

            <p><strong>Unternehmen</strong> (in Folgenden „Nutzer a“ genannt)<br>
            <ul>
              <li>
                definieren auf der Plattform ihre für Mitarbeiterinnen und Mitarbeiter je nach Funktion im Unternehmen (Auszubildende, Fachkräfte, Führungskräfte) gültigen <strong>Anforderungsprofile</strong>,
              </li>
              <li>präsentieren das Unternehmen auf der Plattform anhand eines <strong>Firmenprofils</strong> allgemein, insbesondere für jene Personengruppe, bei der Personalbedarf besteht (offene Stellen) und deren Eignungsprofil dem Anforderungsprofil des Unternehmens entspricht.</li>
            </ul>
          </p>

          <p><strong>Arbeitnehmer*innen</strong> (Personen im Vorfeld des Berufslebens oder auf der Suche nach einer neuen Arbeits-/Ausbildungsstelle; im Folgenden genannt: „Nutzer b“)
          <ul>
            <li>
              ermitteln auf der Plattform ihre persönlichen Stärken im beruflichen und privaten Kontext (<strong>"persönliches Stärke-/Eignungsprofil"</strong>),
            </li>
            <li>
              erhalten auf der Plattform <strong>Zugang und Kontakt</strong> zu den Firmenprofilen jener Unternehmen, wo Anforderungs- und persönliches Eignungsprofil einander entsprechen.
            </li>
          </ul>
            Die Details der Nutzung dieser Plattform regelt ein spezieller <strong>Nutzungsvertrag</strong>.
          </p>

          <h3>2. Inhalt und Art der Daten</h3>

          <p>Zum Zwecke der Vertragserfüllung werden folgende Daten im Internet erhoben:</p>

          <h4>2.1 Daten des Nutzers a</h4>  

          <ol>
            <li>
              Kontaktdaten
              <ul>
                <li>Name</li>
                <li>Branche</li>
                <li>Adresse, Telefon, Email</li>
              </ul>
            </li>
            
            <li>
              Anforderungsprofile je nach Funktion im Unternehmen (Auszubildende, Fachkräfte, Führungskräfte)
            </li>
            
            <li>
              Daten, die das Unternehmen entsprechend den vertraglichen Vereinbarungen unter dem Titel „Über Uns“ bereitstellt 
            </li>
          </ol>

          <h4>2.2 Daten des Nutzers b</h4>

          <ol>
            <li>Zugangsdaten
              <ul>
                <li>Login-E-Mail-Adresse</li>
                <li>Passwort</li>

              </ul>
            </li>
            <li>Persönliche Daten</li>
              <ul>
                <li>Name</li>
                <li>Vorname</li>
                <li>Adresse, Telefon, Email</li>
                <li>Zeit im Beruf</li>
                <li>Zeit als Führungskraft</li>
                <li>Aktueller Berufstitel</li>
                <li>Bildungsabschluss</li>
              </ul>
            <li>Daten, die der Berechnung der persönlichen Stärken im beruflichen und privaten Kontext des Nutzers b dienen („persönliches Stärke-/Eignungsprofil“).</li>
            <li>Rahmendaten zu Arbeitsstellen
              <ul>
                <li>Jobtitel</li>
                <li>Ort der Arbeitstelle</li>
                <li>Umkreis um den Arbeitsort</li>
                <li>Wunschgehalt</li>
              </ul>
            </li>
          </ol>

          <p>Es handelt sich hierbei um Angaben des Nutzers b zu 
            <ul>
              <li>dem bisherigen schulischen und beruflichen Werdegang</li>
              <li>Vorlieben für einen Beruf / eine Berufsausbildung bzw. Ausbildungsstelle</li>
              <li>Selbstauskünfte zum außerberuflichen Umfeld (Hobbies …)</li>
            </ul>
          </p>

          <p>Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt werden kann. </p>

          <h3>Datenverarbeitung</h3>

          <p>Zum Zwecke der Vertragserfüllung verarbeitet die Plattform personen- und unternehmensbezogene Daten im Rahmen der vertraglichen Vereinbarungen mit den „Kunden“ (= Nutzer a und Nutzer b). Diese vertraglichen Vereinbarungen berücksichtigen die für Deutschland geltende EU-Datenschutzgrundverordnung (DSGVO). </p>

          <p>Der Verständlichkeit der Datenverarbeitung dienen folgende Begriffserklärungen</p>

          <h4>3.1 Begriffserklärungen</h4>

          <p><strong>Personenbezogene Daten</strong> sind alle Informationen, die sich auf Nutzer b beziehen.</p>

          <p><strong>Verarbeitung</strong> ist jeder mit Hilfe automatisierter Verfahren ausgeführter Vorgang im Zusammenhang mit personenbezogenen Daten: 
            <ul>
              <li>Datenerhebung </li>
              <li>Verfahren zur Ermittlung des persönlichen Stärke-/Eignungsprofils <strong>(nicht Profiling!)</strong></li>
              <li>Verfahren zur Ermittlung der Entsprechung von persönlichem Stärke-/Eignungsprofil und Anforderungsprofi</li>
              <li>Datenlöschung</li>
            </ul>
          </p>

          <p><strong>Verantwortlicher</strong> ist die von aquadrat – Dr Meyer, Krombholz, Heinze, Leuchtenstern, Romer GbR als verantwortlich für die Verarbeitung personenbezogener Daten ausgewiesene natürliche oder juristische Person.</p>

          <p><strong>Empfänger:</strong> Nutzer a und Nutzer b im Rahmen der vertraglichen Vereinbarungen.</p>

          <p>Anmerkung: Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger; die Verarbeitung dieser Daten durch die genannten Behörden erfolgt im Einklang mit den geltenden Datenschutzvorschriften gemäß den Zwecken der Verarbeitung.</p>

          <p><strong>Einwilligung:</strong> Jede freiwillig für den jeweiligen Fall unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der Nutzer b zu verstehen gibt, dass er mit der Verarbeitung der ihn betreffenden personenbezogenen Daten einverstanden ist.</p>

          <h4>3.2 Spezifikation des Datenschutzes bei der Datenverarbeitung</h4>

          <h5>3.2.1 Verarbeitung der Daten des Nutzers a</h5>

          <p>Diese Daten sind für alle Nutzerinnen und Nutzer der Plattform immer und uneingeschränkt sichtbar. Eine Ausnahme können die Unternehmensspezifischen Anforderungsprofile bilden. Über deren Sichtbarkeit entscheidet Nutzer a. </p>

          <p>Die Daten von Nutzer a können entsprechend den vertraglichen Vereinbarungen vom Unternehmen jederzeit verändert oder ergänzt werden. Die Berücksichtigung markenschutz- und wettbewerbsrechtlicher Vorgaben hierbei liegt im Verantwortungsbereich des Unternehmens. Ihre Speicherdauer endet mit dem Erlöschen des Nutzerkontos.</p>

          <p>Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 I b) DSGVO</p>

          <h5>3.2.2 Verarbeitung der Daten des Nutzers b</h5>

          <p>Die Verarbeitung der Daten des Nutzers b erfolgt gemäß Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO). Es sind technische und organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der verarbeiteten personenbezogenen Daten sicherzustellen. Datenübertragungen über das Internet können aber grundsätzlich Sicherheitslücken enthalten. Es kann ein 100 %iger Schutz nicht gewährleistet werden.</p>

          <p>In Ausnahmenfällen gemäß Art 2 d DSGVO (= bei der rechtlichen Verpflichtung zur Herausgabe bestimmter Daten an die zuständigen Behörden zum Zwecke der Verhütung, Ermittlung, Aufdeckung oder Verfolgung von Straftaten oder der Strafvollstreckung, einschließlich des Schutzes vor und der Abwehr von Gefahren für die öffentliche Sicherheit, oder zur Durchsetzung der Rechte am geistigen Eigentum) erfolgt diese Herausgabe unter gleichzeitiger Information der betroffenen Kundin/des betroffenen Kunden, sofern die gesetzlichen Vorgaben dieses nicht explizit verbieten.</p>

          <p><strong>Die Zugangsdaten</strong> sind für Dritte nicht einsehbar. Die Weitergabe an Dritte (Behörden) erfolgt nur unter dem o.g. gesetzlichen Zwang. Ihre Speicherdauer endet mit dem Erlöschen des Nutzerkontos.</p>

          <p>Rechtsgrundlage für die Verarbeitung dieser Daten sind ist Art. 6 I a und b DSGVO.</p>

          <p><strong>Die Daten für die Berechnung des persönlichen Stärke-/Eignungsprofils</strong> sind für Dritte nicht einsehbar. Sie werden ausschließlich für die Berechnung des persönlichen Stärke-/Eignungsprofils verwendet. Sie dienen in keiner Weise einer als „Profiling“ zu verstehenden Bewertung der Person. Die Weitergabe an Dritte (Behörden) erfolgt nur unter dem o.g. gesetzlichen Zwang. Das aus diesen Daten errechnete persönliche Stärke-/Eignungsprofil ergeht ausschließlich als Rückmeldung an den jeweiligen Nutzer b. Es bleibt in seinem Ermessen, aus dem Vergleich mit den Anforderungsprofilen der Nutzer a mit diesen in Kontakt zu treten.</p>

          <p>Die Speicherdauer dieser Daten endet mit dem Erlöschen des Nutzerkontos.</p>

          <p>Rechtsgrundlage für die Verarbeitung dieser Daten sind Art. 5 a bis d, sowie 6 I a und b DSGVO.</p>

          <h5>3.2.3 Die Kommunikation zwischen Plattform und Nutzer</h5>

          <p>Beim Aufrufen der Plattform werden durch den auf dem Nutzer-Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server der Plattform gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Zutun des Nutzers erfasst und bis zur automatisierten Löschung gespeichert:
            <ul>
              <li>IP-Adresse des anfragenden Rechners</li>
              <li>Datum und Uhrzeit des Zugriffs</li>
              <li>Name und URL der abgerufenen Datei</li>
              <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
              <li>verwendeter Browser und ggf. das Betriebssystem des Rechners sowie der Name des Access-Providers des Nutzers</li>
            </ul>
          </p>
          <p>Die genannten Daten werden zu folgenden Zwecken verarbeitet:
            <ul>
              <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Plattform,</li>
              <li>Gewährleistung einer komfortablen Nutzung der Plattform,</li>
              <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
              <li>zu weiteren administrativen Zwecken.</li>
            </ul>
          </p>

          <p>Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO.</p>

          <p>Unsere Plattform setzt auf Ihrer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die der Browser automatisch erstellt und die auf dem Nutzer-Endgerät (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn der Nutzer die Plattform besucht. Cookies richten auf dem Nutzer-Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Der Einsatz von Cookies dient einerseits dazu, die Nutzung der Plattform für den Nutzer angenehmer zu gestalten. Sie werden z.B. eingesetzt, um dem Nutzer die Möglichkeit zu geben, die Plattform für einige Zeit zu verlassen und später an der gleichen Stelle fortzufahren. Diese werden nach Beendigung des Vertragsverhältnisses automatisch gelöscht.</p>

          <p>Die auf unserer Webseite veröffentlichten Links werden von uns mit größtmöglicher Sorgfalt recherchiert und zusammengestellt. Wir haben jedoch keinen Einfluss auf die aktuelle und zukünftige Gestaltung und Inhalte der verlinkten Seiten. Wir sind nicht für den Inhalt der verknüpften Seiten verantwortlich und machen uns den Inhalt dieser Seiten ausdrücklich nicht zu Eigen. Für illegale, fehlerhafte oder unvollständige Inhalte sowie für Schäden, die durch die Nutzung oder Nichtnutzung der Informationen entstehen, haftet allein der Anbieter der Web-Site, auf die verwiesen wurde. Die Haftung desjenigen, der lediglich auf die Veröffentlichung durch einen Link hinweist, ist ausgeschlossen. Für fremde Hinweise sind wir nur dann verantwortlich, wenn wir von ihnen, d.h. auch von einem eventuellen rechtswidrigen bzw. strafbaren Inhalt, positive Kenntnis haben und es uns technisch möglich und zumutbar ist, deren Nutzung zu verhindern.</p>

          <h3>5. Google Sign-In</h3>

          <p>Nutzer der Gruppe b können sich sich zur Erstellung eines Kundenkontos bzw. zur Registrierung für Google Sign-In entscheiden. Diese wird von Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA ("Google") betrieben. Im Rahmen der sog. Single Sign On können sich diese Nutzer anmelden, falls sie über ein Google-Profil verfügen. Zugangspunkte "Google Sign-In" bzw. "Mit Google registrieren" auf unserer Internetseite erkennen Sie an einem roten Button mit dem Google-Logo und der Aufschrift "Google Sign-In" bzw. "Mit Google registrieren" bzw. "Mit Google anmelden" bzw. "Anmeldung mit G".</p>

          <p>Über die Verwendung dieses Google-Buttons auf unserer Website haben Sie die Möglichkeit, sich mittels Ihrer Google-Nutzerdaten auf unserer Website einzuloggen bzw. zu registrieren. Ausschließlich dann, wenn Sie vor dem Anmeldeprozess auf Basis eines entsprechenden Hinweises über den Austausch von Daten mit Google Ihre ausdrückliche Einwilligung gemäß Art. 6 Abs. 1 lit. a) DSGVO erteilen, erhalten wir bei Verwendung des Google- Buttons von Google, abhängig von Ihren persönlich getroffenen Datenschutzeinstellungen bei Google, die in Ihrem Profil hinterlegten allgemeinen und öffentlich zugänglichen Informationen. Zu diesen Informationen gehören hierbei die Nutzer-ID, der Name, das Profilbild, das Alter und das Geschlecht.</p>

          <p>Wir weisen darauf hin, dass es nach Änderungen der Datenschutzbedingungen und Nutzungsbedingungen von Google bei Erteilung der Einwilligung auch zu einer Übertragung Ihrer Profilbilder, der Nutzer-IDs Ihrer Freunde und der Freundesliste kommen kann, wenn diese in Ihren Privatsphäreeinstellungen bei Google als "öffentlich" markiert wurden. Die von Google übermittelten Daten werden bei uns zur Erstellung eines Benutzerkontos mit den notwendigen Daten, falls diese von Ihnen bei Google dazu freigegeben wurden (Anrede, Vorname, Nachname, Adressdaten, Land, E-Mail Adresse, Geburtsdatum), gespeichert und verarbeitet. Umgekehrt können auf Basis Ihrer Einwilligung Daten (z.B. Informationen zu Ihrem Surf- bzw. Kaufverhalten) von uns an Ihr Google-Profil übertragen werden.</p>

          <p>Die erteilte Einwilligung kann jederzeit durch eine Nachricht an den zu Beginn dieser Erklärung genannten Verantwortlichen widerrufen werden.</p>

          <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Google sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Google: <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a>.</p>

          <p>Die Nutzungsbedingungen für die Verwendung von "Google Sign-In" bzw. "Mit Google registrieren" können Sie hier einsehen: <a href="https://www.google.de/intl/de/policies/terms/regional.html">https://www.google.de/intl/de/policies/terms/regional.html</a>.</p>

          <p>Wenn Sie nicht möchten, dass Google die über unseren Webauftritt gesammelten Daten unmittelbar Ihrem Google-Profil zuordnet, müssen Sie sich vor Ihrem Besuch unserer Website bei Google ausloggen.</p>

          <h2>6. Nutzerrechte</h2>

          <p>Nutzer der Plattform haben das Recht:
            <ul>
              <li>gemäß Art. 15 DSGVO Auskunft über ihre personenbezogenen Daten zu verlangen. Insbesondere können sie Auskunft über die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, zu verlangen</li>
              <li>gemäß Art. 17 DSGVO die Löschung ihrer gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist</li>
              <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung ihrer personenbezogenen Daten zu verlangen, soweit dies nicht auf die Auflösung des Nutzervertrags hinausläuft. In letzterem Fall entspricht das Verlangen nach Einschränkung der Vertragsauflösung</li>
              <li>gemäß Art. 20 DSGVO die personenbezogenen Daten, sowie das Ergebnis der Verarbeitung in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten und/oder die Übermittlung an anderen Personen zu verlangen</li>
              <li>gemäß Art. 7 Abs. 3 DSGVO ihre einmal erteilte Einwilligung jederzeit zu widerrufen. Dies hat zur Folge, dass die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortgeführt wird</li>
            </ul>
          </p>

          <h2>7. Datensicherheit</h2>

          <p>Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils höchsten Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schüssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.</p>

          <p>Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>

          <h2>Aktualität und Änderung dieser Datenschutzerklärung</h2>

          <p>Durch die Weiterentwicklung der Plattform oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter <router-link to="/datenschutz">Datenschutz</router-link> abgerufen und ausgedruckt werden.</p>

          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-center mt-3">&nbsp;</div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      password: null,
    };
  },
  methods: {},
};
</script>
