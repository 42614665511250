<template lang="html">

<div class="container">
	<div class="row align-items-center mt-3">
		<div class="col-sm-12">
			<div class="card">
				<div class="card-header" >
					Impressum 
				</div>
				<div class="card-body" >
					<p>
						Anbieter:<br />
						aquadrat – Dr Meyer, Krombholz, Heinze, Leuchtenstern, Romer GbR<br />
						Pfaffenschlag 10<br />
						96114 Hirschaid
					</p>
					<p>
						Kontakt:<br />
						Telefon: + 49 (0) 911 92 86 76 52<br />
						Telefax: + 49 (0) 911 92 86 76 53<br />
						E-Mail: Florian@Leuchtenstern.com<br />
						Website: <router-link to="/home">www.dein-idealer-job.de</router-link></p>
					<p> </p>
					<p>Bei redaktionellen Inhalten:</p>
					<p>Verantwortlich nach § 55 Abs.2 RStV<br />Florian Leuchtenstern<br />Beim Wahlbaum 73<br />90453 Nürnberg</p>
				</div>
			</div>
		</div>
	</div>
	<div class="row align-items-center mt-3">
		&nbsp;
	</div>
	</div>
</template>

<script>
import axios from 'axios';
export default{
	props:['id'],
	data() {
		return{
			password: null,
		}
	},
	methods:{
	}
}
</script>