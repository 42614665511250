import GAuth from 'vue-google-oauth2'
import Vue from 'vue'
import axios from 'axios'

const md5 =  require('md5');


let company_auth = new Vue({
	computed: {
		get: function() {
			return localStorage.getItem('id_token');
		},
		set: function(id_token) {
			localStorage.setItem('id_token', id_token);
		}
		,
    accessToken: {
      get: function() {
        return localStorage.getItem('access_token')
      },
      set: function(accessToken) {
        localStorage.setItem('access_token', accessToken)
      }
    },
    expiresAt: {
      get: function() {
        return localStorage.getItem('expires_at')
      },
      set: function(expiresIn) {
        let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime())
        localStorage.setItem('expires_at', expiresAt)
      }
    },
    company: {
      get: function() {
        return JSON.parse(localStorage.getItem('company'))
      },
      set: function(company) {
        localStorage.setItem('company', JSON.stringify(company));
        localStorage.setItem('userType', 'company');
        localStorage.removeItem('user')
      }
    }
  },
  methods: {
    login() {
	},
	
  async loginGoogle() {
      let gUser;
		await this.$gAuth
		.signIn()
		.then(GoogleUser => {
		  gUser = GoogleUser;
	})
	.catch(error => {
		console.log("----ERROR Google Auth----");
		console.log(error);
	})
    return gUser;
	},
    logout() {
      return new Promise((resolve, reject) => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('id_token')
        localStorage.removeItem('expires_at')
        localStorage.removeItem('user')
        localStorage.removeItem('company')
        localStorage.removeItem('userType')
        webAuth.logout({
          //returnTo: 'http://SOMEWHERE.ELSE.com', // Allowed logout URL listed in dashboard
          clientID: '427642123675-h9e0e7g2ngnph027ceps73mm8m7sveq7.apps.googleusercontent.com', // Your client ID
        })
      })
    },
    
    isCompanyAuthenticated() {
      let company = JSON.parse(localStorage.getItem("company"));
      if (company) {
        var base64Url = company.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        const exp = JSON.parse(jsonPayload)['exp'];
        const now = new Date();
        var date = new Date(exp * 1000);

        if (now.getTime() > date.getTime()) {
          localStorage.removeItem("company");
          return false;
        }
        axios.defaults.headers['x-access-token'] = company;
        return {'x-access-token': company};
      }
    },
    
    handleCompanyAuthentication() {
      return new Promise((resolve, reject) => {
        webAuth.parseHash((err, authResult) => {

          if (authResult && authResult.accessToken && authResult.idToken) {
            this.expiresAt = authResult.expiresIn
            this.accessToken = authResult.accessToken
            this.token = authResult.idToken
            this.company = authResult.idTokenPayload
            resolve()

          } else if (err) {
            this.logout()
            reject(err)
          }

        })
      })
    }
  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$company_auth = company_auth
  }
}
