"use strict";

export let validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export let validatePassword = (pw) => {

    // AWS Cognito PW constraints
    var aws_cognito_pw_constraints

    // TARGET_ENV comes from webpack.config.js
    if (process.env.TARGET_ENV === 'development') {
        aws_cognito_pw_constraints = process.env.AWS_COGNITO_PW_CONSTRAINTS_ENV_DEV;

    }

    if (process.env.TARGET_ENV === 'staging') {
        aws_cognito_pw_constraints = process.env.WS_COGNITO_PW_CONSTRAINTS_ENV_STAGE;

    }

    if (process.env.TARGET_ENV === 'production') {
        aws_cognito_pw_constraints = process.env.WS_COGNITO_PW_CONSTRAINTS_ENV_PROD;

    }

    // const re = new RegExp(aws_cognito_pw_constraints);
    const re = new RegExp(aws_cognito_pw_constraints);
    return re.test(String(pw));

};
