import Vue from 'vue'
import axios from 'axios'

let requestHandling = new Vue({
  data() {
    return {
      successMessage: null,
      showSuccessMessage : false,
      errrorMessage: null,
      showErrorMessage: false,
      backend_url : null,
    }
  },
  computed: {
  },

  methods: {
    async  get(url, param) {
              this.$loading(true);
              let resp;
              await axios.get(url, param)
                .then(response => {
                  resp = response.data;
                  this.$loading(false);
                })
                .catch(error => {
                  console.log("---- ERROR in Get-Method----");
                  console.log(error);
                  this.$loading(false);
                });
      return resp;
    },

    async put(url, param) {
      this.$loading(true);
      await axios.put(url, param)
        .then(response => {
          this.$loading(false);
          this.$messageHandling.handleSuccess("Daten wurden erfolgreich gespeichert");
        })
        .catch(error => {
          console.log("----ERROR----");
          console.log(error);
          this.$loading(false);
        })
    },

    async post(url, param) {

      this.$loading(true);
      let resp;
      await axios.post(url, param)
        .then(response => {
          resp =  response.data;
          })
        .catch(error => {
          console.log("----ERROR----");
          console.log(error.message);
          this.$messageHandling.handleError("Aktion konnte nicht ausgeführt werden");
        })
      this.$loading(false);
      return resp;
    }, 

  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$requestHandling = requestHandling
  }
}
