<template lang="html">

<div class="container-fluid">
	<h1 class="center"> Meine Daten </h1>
	<div class="row align-items-center mt-3">
		<div class="col-sm-12">
			<div class="card">
				<div class="card-header" >
					Meine persönlichen Daten
				</div>
				<div class="card-body content-center p-3" >
					<form class="form-horizontal justify-content-center" role="form">
						<fieldset>
							<div class="row form-group">
								<div class="col col-sm-6">
									<label class="control-label"  for="firstname">Vorname</label>
									<div class="">
										<input type="text" id="firstname" @change="setDirty()" v-model="userdata.firstname" class="form-control">
									</div>
								</div>
								<div class="col col-sm-6">
									<label class="control-label"  for="lastname">Nachname</label>
									<div class="">
										<input type="text" id="lastname" @change="setDirty()" v-model="userdata.lastname" class="form-control">
									</div>
								</div>
							</div>

							<div class="form-group row">
								<label class="control-label col-sm-10" for="email" placeholder="E-mail" >E-mail</label>
								<div class="col-sm-12">
									<input type="text" readonly id="email" @change="setDirty()" v-model="userdata.email" class="form-control" >
								</div>
							</div>

              <!-- Password change block -->

							<div class="form-group row mt-4">
								<label class="control-label col-sm-10" for="oldPw">Bisheriges Passwort (das haben wir uns sicherheitshalber nicht gemerkt)</label>
								<div class="col-sm-12">
									<input type="password" id="oldPw" v-model="userdata.oldPw" class="form-control">
								</div>
							</div>              

							<div class="form-group row">
								<label class="control-label col-sm-10" for="newPw">Neues Passwort</label>
								<div class="col-sm-12">
									<input type="password" id="newPw" v-model="userdata.newPw" class="form-control">
								</div>
							</div>

              
							<div class="form-group row">
								<label class="control-label col-sm-10" for="newPwConfirm" >Bestätigung neues Passwort</label>
								<div class="col-sm-12">
									<input type="password" id="NewPwConfirm" v-model="userdata.newPwConfirm" class="form-control">
								</div>
							</div>

              <div class="text-center mt-2">
										<button type="button" class="btn btn-primary" v-on:click="changePw" >Passwort ändern</button>
									</div>

              <!-- Phone and address -->

              <div class="form-group row mt-4">
								<label class="col-sm-12 control-label" for="textinput">Telefonnummer (optional)</label>
								<div class="col-sm-12">
									<input type="text" placeholder="Telefonnnummer" id="telephone" @change="setDirty()" v-model="userdata.telephone" class="form-control">
								</div>
							</div>
							
              <div class="form-group row">
								<div class="col col-sm-12">
									<label class="control-label" for="textinput">Straße und Hausnummer</label>
									<div class="">
										<input type="text" id="Strasse" @change="setDirty()" v-model="userdata.street" class="form-control">
									</div>
								</div>
              </div>
							
              <!-- <div class="form-group row"> -->
              
              <div class="form-group row">

                  <div class="col col-sm-12">
								
                    <label class="control-label" for="textinput">Postleitzahl oder Wohnort</label>
                
                    <input 
                      class="col-sm-12 form-control"
                      type="text" 
                      id="city" 
                      list="selectedCity" 
                      @change="setDirty(); checkIfCityValid()" 
                      v-model="currentZipAndPlace">
                              <datalist  id ="selectedCity">
                                <option v-for="item in cityList">{{item.plz}} - {{item.stadt}}</option>
                              </datalist>

                    <label
                      v-if="!isValidCity && !this.isEmptyCity"
                      id="invalidCity" 
                      class="control-label text-danger" 
                      for="textinput">Die Postleitzahl/Stadt-Kombination ist nicht gültig. Bitte gib eine gültige Kombination aus der Liste an.</label>

                    <div class="text-center">
                      <button 
                        type="button"
                        id="btnResetCity" 
                        class="m-2 btn btn-outline-primary" 
                        @click="setDirty(); resetCity()">Ort/PLZ leeren
                      </button>
                    </div>
                    
                    <div class="text-center">
                      <router-link class="btn btn-outline-primary" to="/signedin">
                        Zurück
                      </router-link>
										  <button type="button" class="btn btn-primary" v-on:click="updatePersonalData()" >Speichern</button>
									  </div>
                  
                  </div>

              </div>

              <div class="form-group row mt-3">

                  <div class="col col-sm-12">

                    <div class="text-center mt-2">
										
                        <button type="button" class="btn btn-primary btn-danger" v-on:click="activateDeleteBlock()" >Meinen Account löschen</button>
									  
                    </div>

                    <div v-if=isDelBlockActive>
									  
                        <label class="control-label" for="DeleteConfirmText">Bitte gib <span style="color:#FF0000">JA </span> ein, um den Account wirklich zu löschen</label>
									      <div class="">
										
                          <input type="text" id="DeleteConfirmText" v-model="userdata.deleteActiveUser" class="form-control">
									  
                         </div>

                         <div class="text-center mt-2">
                      
                          <button type="button" class="btn btn-outline-danger" v-on:click="keepCurrentUser()">Behalten</button>
										      
                            <button type="button" class="btn btn-danger" v-on:click="delCurrentUser()">Löschen</button>
									      </div>
  
                     </div>
             
                  </div>
                
              </div>



						</fieldset>
					</form>
				</div>
			</div>
		</div>
	</div>

	<div class="row align-items-center mt-3">
		&nbsp;
	</div>
	</div>
</template>


<script>
import { awsCognitoChangePw, awsCognitoDeleteUser } from "../api/awsCognito"


export default {
  props: ["id"],
  data() {
    return {
      cityList: null,
		  isDirty: false,
      isValidCity: true,
      isEmptyCity: false,
      isDelBlockActive: false, //Delete Block is active after click on "Mein Account löschen"
      currentZipAndPlace: null,
      userdata: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        telephone: null,
        street: null,
        oldPw: null,
        newPw: null,
        deleteActiveUser: null,
        newPwConfirm: null,
        job: {
          id: null,
          name: null
        },
        jobSince: null,
        executiveSince : null,
        plz: {
          id: null,
          plz: null,
          name: null,
          detailed: null
        }
      }
    };
  },

  methods: {

    checkIfCityValid() {

      let plzFound

      // plzFound undefined if plz does not exist
      if (this.currentZipAndPlace === null) {

        this.isValidCity = false;
        return;

      }
      
      const activePlz = this.currentZipAndPlace.split(' - ')[0];
      
        
      plzFound = this.cityList.find(city => city.plz === activePlz);
      
      if (plzFound === undefined) {

        
        this.isValidCity = false;
        return;

      }

      this.isValidCity = true;
      return;

    },

    checkIfCityEmpty() {
      if (this.currentZipAndPlace == '') {
        this.isEmptyCity = true
      } else {
        this.isEmptyCity = false
      }
    },


    resetCity() {
      this.currentZipAndPlace = ''
      this.userdata.plz.detailed = ''
    },

    setDirty() {
      this.isDirty = true;
    },

    
    setCityData() {
      this.userdata.plz = this.$city.getObjectByFullName(this.userdata.plz.detailed);
    },

    activateDeleteBlock () {
      this.isDelBlockActive = true
    },

    deActivateDeleteBlock () {
      this.isDelBlockActive = false
    },

    updatePersonalData() {

      //check if zip-code - city combination is valid
      this.checkIfCityValid();

      //check if zip-code - city field ist empty
      this.checkIfCityEmpty();

      //if zip-city-combination not valid and not empty -> get current values as written in DB
      if(this.isEmptyCity == false &&  this.isValidCity == false) {
        this.userdata.plz.detailed = this.userdata.plz.plz + " - " + this.userdata.plz.stadt
      };

      //if zip-city-combination is empty -> null
      if(this.isEmptyCity == true) {
        this.userdata.plz.id = null;
        this.userdata.plz.detailed = null
      };

      //if zip-city-combination is empty -> value from field
      if(this.isValidCity == true) {
        this.userdata.plz.detailed = this.currentZipAndPlace;
        this.userdata.plz = this.$city.getObjectByFullName(this.userdata.plz.detailed)
      };

      //Only execute update if anything has changed
      if (!this.isDirty) {
        return;
      }

      // Update user data
      this.$user.updateUser(this.userdata)
        .then(resp => {
          window.scrollTo(0,0);
        });

    },

    delCurrentUser() {

      if(this.userdata.deleteActiveUser == "JA") {

        awsCognitoDeleteUser()
          .then(this.$requestHandling.post(this.$router.app.backend_url + 'user/delUser/'))
          .then(localStorage.removeItem("user"))
          .then(localStorage.removeItem("company"))
          .then(localStorage.removeItem("userType"))
          .then(localStorage.removeItem("cognitoToken"))
          .then(this.isLoggedIn = false)
          .then(this.$router.app.loggedIn = false)
          .then(this.$router.push({
              name: "home",
              params: { successMessage: "Der Nutzer wurde erfolgreich gelöscht" }
          }));
                 
      } else {
        
        this.userdata.deleteActiveUser = ''

      };

    },

    keepCurrentUser() {

      this.userdata.deleteActiveUser = '';
      this.deActivateDeleteBlock()

    },
    
    getCityList() {
      this.$city.getCityList()
      .then(resp => {
        this.cityList = resp;
      })
    },

    getUserData(userid) {
      this.$user.getUserData(userid)
      .then(data => {
        
        this.userdata = data;

        if (data.plz.detailed !== null) {
          this.currentZipAndPlace = data.plz.detailed;
        } else {
          this.currentZipAndPlace = ""
        }
      });
    },

    changePw() {

      if(this.userdata.newPw == this.userdata.newPwConfirm){

        awsCognitoChangePw(this.userdata.email, this.userdata.oldPw, this.userdata.newPw)

      } else {

      };

    },


  },

  created() {
    // Get userdata by JWT
    this.getUserData(this.$router.app.jwtHandler.getIdByJWT());
    this.getCityList();
  },

};
</script>
