var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container text-center"},[_c('h1',{staticClass:"center"},[_vm._v("Meine Werte")]),_vm._v(" "),[_c('div',{staticClass:"card",attrs:{"role":"tablist"}},_vm._l((_vm.groupdQuestions),function(item,index){return _c('b-card',{key:item.id,staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion-' + index),expression:"'accordion-' + index"}],staticClass:"card-header",staticStyle:{"cursor":"pointer"},attrs:{"header-tag":"header","role":"tab","id":'accordion-header-' + index},on:{"click":function($event){return _vm.reset(index)}}},[_vm._v("\n          "+_vm._s(item.name)+" - ("+_vm._s(_vm.openItems(index))+")\n        ")]),_vm._v(" "),_c('b-collapse',{attrs:{"id":'accordion-' + index,"accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',{staticClass:"card-body"},[_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col col-centered"},[_c('b-progress',{attrs:{"value":_vm.groupdQuestions[_vm.key].data[_vm.questionid].currentValue,"max":_vm.groupdQuestions[_vm.key].data[_vm.questionid].maxValue,"animated":""}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col col-centered col-md-offset-2",style:(_vm.compOpacityTop),attrs:{"aria-colspan":"2"}},[_vm._v("\n                "+_vm._s(_vm.groupdQuestions[_vm.key].data[_vm.questionid].itm_stmt1)+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"row mt-4 mb-4"},[_c('div',{staticClass:"col col-centered d-flex align-items-center justify-content-center"},[_c('vue-slider',{attrs:{"min":1,"max":99,"height":250,"tooltip":'none',"dot-style":{ backgroundColor: '#36abff' },"dotSize":25,"marks":_vm.marks1,"process":false,"direction":"ttb","id":'slider-input-' +
                    index +
                    '-' +
                    _vm.groupdQuestions[_vm.key].data[_vm.questionid].itm_number},model:{value:(
                    _vm.groupdQuestions[_vm.key].data[_vm.questionid].user_items[0].value
                  ),callback:function ($$v) {_vm.$set(_vm.groupdQuestions[_vm.key].data[_vm.questionid].user_items[0], "value", $$v)},expression:"\n                    groupdQuestions[key].data[questionid].user_items[0].value\n                  "}})],1)]),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col col-centered",style:(_vm.compOpacityBottom)},[_vm._v("\n                "+_vm._s(_vm.groupdQuestions[_vm.key].data[_vm.questionid].itm_stmt2)+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"row mt-2"},[_c('div',{staticClass:"col col-centered"},[(_vm.isButtonNextQuestion)?_c('button',{staticClass:"btn btn-primary pull-right",attrs:{"type":"button","data-dismiss":"alert","aria-label":"Close","id":'buttonSave-' +
                    index +
                    '-' +
                    _vm.groupdQuestions[_vm.key].data[_vm.questionid].itm_number},on:{"click":function($event){return _vm.nextQuestion(_vm.groupdQuestions[_vm.key].data[_vm.questionid], _vm.key)}}},[_vm._v("\n                  "+_vm._s(_vm.groupdQuestions[_vm.key].data[_vm.questionid].buttonDescription)+"\n                ")]):_vm._e(),_vm._v(" "),(_vm.isButtonFinalQuestion)?_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion-' + index),expression:"'accordion-' + index"}],staticClass:"btn btn-primary pull-right",attrs:{"type":"button","aria-label":"Close","id":'buttonSave-' +
                    index +
                    '-' +
                    _vm.groupdQuestions[_vm.key].data[_vm.questionid].itm_number}},[_vm._v("\n                  Fertig\n                ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"row mt-2 text-center"},[_c('div',{staticClass:"col col-centered"},[_c('p',{staticClass:"font-weight-light"},[_vm._v("Copyright Harald Meyer.")])])])])],1)],1)}),1)],_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('br'),_vm._v(" "),_c('router-link',{staticClass:"btn btn-outline-primary",attrs:{"to":"/signedin"}},[_vm._v("Zurück")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }