<template lang="html">
	<div class="container text-center" >
		<h1 class="display-4">Ich bin neu!</h1>
		<p class="lead"></p>
		<form>

		<div class="form-group text-left mb-0 mt-4">
			<label class="control-label" for="email">E-Mailadresse</label>
		</div>
		
		<div class="form-group has-warning">
			<input type="email" class="form-control" v-bind:class="{ 'is-valid': isValidMail, 'is-invalid': !isValidMail }"  @change="validateInitialMail" id="email" v-model="email" placeholder="name@example.com" required >
	  	</div>
		
		<div class="form-group text-left mt-4">
			<label class="control-label" for="confirmemail">Wiederholung E-Mailadresse</label>
		</div>		
		
		<div class="form-group">
			<input type="email" class="form-control" v-bind:class="{ 'is-valid': isValidCompareMail, 'is-invalid': !isValidCompareMail }" id="confirmemail" v-model="confirmemail" placeholder="name@example.com" @change="compareMail" required>
	  	</div>

		<div class="form-group text-left mb-0 mt-4">
			<label class="control-label" for="textinput">Passwort (mind. 6 Buchstaben, Ziffern oder Sonderzeichen)</label>
		</div>

	  	<div class="form-group">
			<input type="password" class="form-control" v-bind:class="{ 'is-valid': isValidPassword, 'is-invalid': !isValidPassword }" id="pw" placeholder="Passwort" v-model="pw" v-on:change="validatePassword" v-on:keyup="validatePassword" required>
	  	</div>

		<div class="form-group text-left mb-0 mt-4">
			<label class="control-label" for="textinput">Wiederholung Passwort</label>
		</div>

	  	<div class="form-group">
			<input type="password" class="form-control" v-bind:class="{ 'is-valid': isValidPassword, 'is-invalid': !isValidPassword }" id="confirmpw" placeholder="Bestätigung Passwort" v-model="confirmpw" @change="comparePassword" required>
	  	</div>

      <b-form-checkbox class="mt-4" v-model="agbCheckbox" name="some-radios" value="1">Ich habe die <b-link target="_blank" href="/agb">AGB's</b-link> gelesen und akzeptiert</b-form-checkbox>
      
	  <button class="btn btn-lg btn-primary btn-block mt-4" type="button" v-on:click="signup" :disabled='Object.keys(hasError).length > 0 || agbCheckbox === false'>Anmelden</button>

    </form>
	</div>
</template>


<script>

import { validateEmail } from "../api/validate"
var md5 = require("md5");

export default{
	props:['id'],
	data() {
		return{
      		agbCheckbox: false,
			email: null,
			confirmemail: null,
			pw: null,
			confirmpw: null,
			isValidMail: null,
			isValidCompareMail: null,
			isValidPassword: null,
			hasError: {}
		}
	},
	methods:{
		validateInitialMail() {
			if(validateEmail(this.email)) {
				this.isValidMail = true;
				delete this.hasError['invalidMail'];
			} else {
				this.isValidMail = false;
				this.hasError['invalidMail'] = "Ungültige E-mail";
			}
			this.compareMail();
		},

		compareMail() {
			if(this.email == this.confirmemail) {
				this.isValidCompareMail = true;
				delete this.hasError['invalidCompareMail'];
			} else {
				this.isValidCompareMail = false;
				this.hasError['invalidCompareMail'] = "Die E-mail Adressen stimmen nicht überein";
			}
		},

		validatePassword() {

			// AWS Cognito PW constraints
			var aws_cognito_pw_constraints

			// TARGET_ENV comes from webpack.config.js
			if (process.env.TARGET_ENV === 'development') {
				aws_cognito_pw_constraints = process.env.AWS_COGNITO_PW_RESTRICTION_ENV_DEV;

			}

			if (process.env.TARGET_ENV === 'staging') {
				aws_cognito_pw_constraints = process.env.AWS_COGNITO_PW_RESTRICTION_ENV_STAGE;

			}

			if (process.env.TARGET_ENV === 'production') {
				aws_cognito_pw_constraints = process.env.AWS_COGNITO_PW_RESTRICTION_ENV_PROD;

			}

			const re = new RegExp(aws_cognito_pw_constraints);
			return re.test(String(this.pw));

		},
		
		comparePassword() {

			if(this.pw == this.confirmpw) {
				
				delete this.hasError['invalidPassword'];
				this.isValidPassword = true;

			} else {
				this.hasError['invalidPassword'] = "Die Passwörter stimmen nicht überein";
				this.isValidPassword = false;
			};


		},

		signup() {
			
			this.$user.registerUser(this.email, this.pw, this.agbCheckbox)
	    	.then(response => {
				if(response) {
          			this.$router.push({name: 'signin', params: {successMessage: 'Du hast dich erfolgreich registriert und einen Registrierungs-Link erhalten. Bitte checke deine Mails.'}});
        		} else {
					this.$messageHandling.handleError("Registrierung war nicht erfolgreich. Bist du bereits registriert?");
				}
      		})
		}
	},

	created(){
		let user = JSON.parse(localStorage.getItem('user'))

	}
}
</script>
