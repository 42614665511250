<template lang="html">

	<div class="container-md text-center">

		<form class="form-signin">

				<h1 class="display-4" >Bitte anmelden</h1>

					<div class="form-group mt-4">
						<label v-if="isForgotenPw" 
							class="sr-only" >Bitte gib die E-Mailadresse an, an die wir den Code zum zurücksetzen des Passworts schicken sollen.
						</label>
						
						<label for="username" 
							class="sr-only" v-if="!isForgotenPw">E-Mail-Adresse
						</label>

						<input v-model="username" 
							id="username" class="form-control mt-1" 
							@change="validateUsername"
							v-bind:class="{ 'is-valid': emailIsValid, 'is-invalid': emailIsInvalid }" 
							placeholder="meine@email-adresse.de" 
							required autofocus>
					</div>

					<div v-if="!isForgotenPw" class="form-group mt-4">
						<label for="inputPassword" 
							class="sr-only">Passwort
						</label>
						<input v-model="password" 
							id="password" 
							class="form-control mt-1" 
							type="password" 
							placeholder="Password" 
							v-bind:class="{ 'is-valid': pwIsValid, 'is-invalid': pwIsInValid }"
							@change="validatePW"
							required>
					</div>

					<div v-if="!isForgotenPw"  class="form-group m-4">
						<button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="login">Login</button>
					</div>

	
					<!-- 
						
					<div v-if="!isForgotenPw"  class="form-group">
						<a class="btn btn-outline-dark"   v-on:click="googleLogin" role="button" style="text-transform:none">
							<img width="20px" style="margin-bottom:3px; margin-right:5px" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
							Login with Google
						</a>
					</div> 
				
					-->

					<div v-if="!isForgotenPw" class="form-group mt-4">
						<p>
							<a href="#" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" @click="isForgotenPw=true">
								Passwort vergessen
							</a>
						</p>
					</div>

					<div class="form-group mt-4">
						<button v-if="isForgotenPw" type="button" class="btn btn-lg btn-primary btn-block" 
						v-on:click="startForgotPwFlow">Code anfordern</button>
					</div>


					<div  v-if="isCodeSent" class="form-group mt-4">
						<label for="pwRecoveryCode">Code eingeben</label>
						<input v-model="verificationCode" id="pwRecoveryCode" class="form-control text-center mt-2">
					</div>


					<div class="form-group mt-4">
						<label v-if="isCodeSent" for="NewPw">Neues Passwort</label>
						<input v-if="isCodeSent" v-model="newPw" id="newPw" class="form-control text-center mt-2" type="password">
					</div>					

					<div class="form-group mt-4">
						<button v-if="isCodeSent" type="button" class="btn btn-lg btn-primary btn-block mt-2" v-on:click="setNewPw">Eingabe bestätigen</button>
					</div>

					<div v-if="isForgotenPw" class="form-group mt-4">
						<p>
							<a href="#" 
								class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" @click="isForgotenPw=false; isCodeSent=false">
								Passwort doch nicht vergessen
							</a>
						</p>
					</div>					

				</form>
	</div>

</template>


<script>

import { awsCognitoAuthenticateUser, awsCognitoForgotPw, awsCognitoSetNewPw } from "../api/awsCognito"
import { validateEmail, validatePassword } from "../api/validate"


var md5 = require('md5');
var dummyPw = process.env.DUMMY_PW_ENV

export default{
	props:['id'],
	data() {
		return{
			username: null,
			password: null,
			pwRecoveryCode: null,
			isForgotenPw: false,
			isCodeSent: false,
			emailIsValid: false,
			emailIsInvalid: true,
			pwIsValid: false,
			pwIsInValid: true,
			verificationCode: null,
			newPw: null,
		}
	},
	methods:{
		login() {

			// check if ist is a valid email-address
			if(validateEmail(this.username)==false) {
				this.$messageHandling.handleError("Keine gültige E-Mailadresse.");
				return "Exception - Invalid email address"
			};

			var param = {
				email: this.username,
				password: dummyPw,
			};

			// get access token from AWS Cognito (data-part)
			// if promise fullfilled -> get internal JWT and authenticate user with it (resp-part)
	  		awsCognitoAuthenticateUser(this.username, this.password)
				.then((result) => {			
						localStorage.setItem('cognitoToken', JSON.stringify(result));
						this.$requestHandling.post(this.$router.app.backend_url + 'login', param)
						.then(resp => {
							if(resp) {
								localStorage.setItem('user', JSON.stringify(resp));
								localStorage.setItem('userType', 'user');
								localStorage.removeItem("company");
								this.$router.push({name: 'signedin', params: {successMessage: "Du hast dich erfolgreich angemeldet"}});
							}
						});
				}, (err) => {
						this.$messageHandling.handleError("E-Mailadresse oder Passwort nicht korrekt.");
				});
			
   		 },

		 validateUsername() {


			if (validateEmail(this.username)) {
				this.emailIsValid = true
				this.emailIsInvalid = false	
			} else {
				this.emailIsValid = false
				this.emailIsInvalid = true
			}

		 },

		 validatePW() {

			if (validatePassword(this.password)) {
				this.pwIsValid = true
				this.pwIsInValid = false
			} else {
				this.pwIsValid = false
				this.pwIsInValid = true
			}

		 },


		 startForgotPwFlow () {

			// check if ist is a valid email-address
			if(validateEmail(this.username)==false) {
				this.$messageHandling.handleError("Keine gültige E-Mailadresse.");
				return "Exception - Invalid email address"
			};			

			awsCognitoForgotPw(this.username);
			this.isCodeSent = true

		 },

		 setNewPw () {

			awsCognitoSetNewPw(this.username, this.verificationCode, this.newPw);
			this.isForgotenPw = false
			this.isCodeSent = false
			this.password = this.newPw

		 },

		/*

		googleLogin() {
			this.$auth.loginGoogle().then(GoogleUser => {
        var param =  {
          email: GoogleUser.getBasicProfile().getEmail(),
          token: GoogleUser.getAuthResponse().access_token,
          firstname: GoogleUser.getBasicProfile().getGivenName(),
          lastname: GoogleUser.getBasicProfile().getFamilyName(),
          password: md5(Date.now())
          // imageUrl: GoogleUser.getBasicProfile().getImageUrl()
        };
        this.$requestHandling.post(this.$requestHandling.backend_url + 'login/google', param)
          .then(resp => {
            localStorage.setItem('user', JSON.stringify(resp));
            this.$router.push({name: 'home', params: {successMessage: "Du hast dich erfolgreich mit Google angemeldet"}});
          })
          .catch(error => {
            console.log("----ERROR save persistent----");
            console.log(error);
            this.$messageHandling.handleError("Bei der Anmeldung ist was schief gefaufen");
          })
			});
		}

		*/

	},
	watch : {
    '$route'(res) {
		let user = JSON.parse(localStorage.getItem('user'));
      if(user) {
        this.$router.push({name: 'signedin'});
      }
	}
	}
}

</script>
