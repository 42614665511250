import Vue from 'vue'
import axios from 'axios'
import requestHandler from '../api/RequestHandling'


let city = new Vue({
  data() {
    return {
      cityList: null
    }
  },
  components: {
    requestHandler
  },
  methods: {
    
    async getCityList() {
      let resp;
      if(this.cityList == null) {
        await this.$requestHandling.get(this.$requestHandling.backend_url+'plz', {})
        .then(response => {
          this.cityList = response;
        });
      }
      return this.cityList;
    },
   
    getObjectByFullName(fullName) {
      const selectedPlz = fullName.split(' - ')[0];
      if(selectedPlz != null && selectedPlz != '')  {
        for (var n = 0; n < Object.keys(this.cityList).length; n++) {

          if(selectedPlz == this.cityList[n].plz) {
            let obj = this.cityList[n];
            obj.detailed = fullName;

            return this.cityList[n];
          }

        }
      }
      return null;
    }
  }
});


export default {
  install: function(Vue) {
    Vue.prototype.$city = city;
  }
}
