<template lang="html">
  <div class="container-lg">
    <div class="row">
      <div class="col-sm-12 col-md-4 mt-3">
        <div
          class="card"
          style="cursor: pointer"
          v-on:click="PushTo('/mydata')"
          id="buttonMeineDaten"
        >
          <div class="card-body text-center">
            <font-awesome-icon
              :icon="['fas', 'file-pen']"
              class="fa-5x"
              style="color: #198fcf"
            />
          </div>
          <div class="text-center">Meine Daten</div>
        </div>
      </div>
      <div
        class="col-sm-12 col-md-4 mt-3"
        v-on:click="PushTo('/myquestion')"
        style="cursor: pointer"
        id="buttonMeineWerte"
      >
        <div class="card">
          <div class="card-body text-center">
            <font-awesome-icon
              :icon="['fas', 'heart']"
              class="fa-5x"
              style="color: #198fcf"
            />
          </div>
          <div class="text-center">Meine Werte</div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 mt-3">
        <div
          class="card"
          style="cursor: pointer"
          v-on:click="PushTo('/expectedjob')"
          id="buttonMeineWunschausbildung"
        >
          <div class="card-body text-center">
            <font-awesome-icon
              :icon="['fas', 'user-graduate']"
              class="fa-5x"
              style="color: #198fcf"
            />
          </div>
          <div class="text-center">Meine Wunschausbildung</div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 mt-3">
        <div
          class="card"
          v-on:click="PushTo('/evaluation')"
          style="cursor: pointer"
          id="buttonMeineAuswertung"
        >
          <div class="card-body text-center">
            <font-awesome-icon
              :icon="['fas', 'user-check']"
              class="fa-5x"
              style="color: #198fcf"
            />
          </div>
          <div class="text-center">Meine Auswertung</div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 mt-3">
        <div
          class="card"
          style="cursor: pointer"
          v-on:click="logout()"
          id="buttonAppVerlassen"
        >
          <div class="card-body text-center">
            <font-awesome-icon
              :icon="['fas', 'right-from-bracket']"
              class="fa-5x"
              style="color: #198fcf"
            />
          </div>
          <div class="text-center">App verlassen!</div>
        </div>
      </div>
    </div>

    <div class="row"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      password: null,
    };
  },
  methods: {
    PushTo(route) {
      // Avoid redundant navigation to current location error - check also main.js router.beforeEach-section
      try {
        this.$router.push(route);
      } catch (error) {
        if (!(error instanceof NavigationDuplicated)) {
          throw error;
        }
      }
    },
    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("cognitoToken");
      localStorage.removeItem("company");
      localStorage.removeItem("userType");
      this.isLoggedIn = false;
      this.isUser = null;
      this.isCompany = null;
      this.$router.app.loggedIn = false;
      this.$router
        .push({
          name: "home",
          params: { successMessage: "Du hast dich erfolgreich abgemeldet" },
        })
        .catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // But print any other errors to the console
            logError(err);
          }
        });
    },
  },

  created() {},
  watch: {
    $route() {},
  },
};
</script>
