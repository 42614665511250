<template lang="html">

<div class="container" >

  <!--<div class="col-sm-12">-->
    <h1 class="center"> Meine Wunschausbildung </h1>
  <template>
    <div class="card" role="tablist">
      <b-card no-body class="mb-1" v-for="(item, index) in expectedJobList" v-bind:key="item.id">
        <b-card-header header-tag="header" class="card-header" role="tab" v-b-toggle="'accordion-'+index">
          {{item.headline}}
        </b-card-header>
        <b-collapse :id="'accordion-'+index"  accordion="my-accordion" role="tabpanel">
          <b-card-body class="card-body">
            <div class="form-group">
              
              <label class="col-sm-12 control-label"  for="textinput">Gewünschter Ausbildungsberuf</label>
              
              <div class="col-sm-12">
                <input type="text" id="newJob" list="jobList" @change="setDirty(index)" v-model="item.job.name" class="form-control">
                <datalist id ="jobList">
                  <option v-for="item in jobList">{{item.name}}</option>
                </datalist>

                <label
                  v-if="!isValidJobName"
                  id="invalidJobName" 
                  class="control-label text-danger" 
                  for="textinput">Dieser Ausbildungsberuf existiert nicht. Bitte wähle einen gültigen Beruf aus.
                  </label>

              </div>

              <div class="text-center">
                <button 
                  type="button"
                  id="btnResetCity" 
                  class="m-2 btn btn-outline-primary" 
                  @click="resetExpectedJob(index)">Ausbildungsberuf leeren
                </button>
              </div>  

            </div>

            <div class="form-group">
              <label class="col-sm-12 control-label"  for="textinput">Hier würde ich am liebsten arbeiten</label>
              
              <div class="col-sm-12 control-label" >
                <input type="text"  id="joblocation" list="cityList" @change="setDirty(index)" v-model="item.plz.detailed" class="form-control">
                <datalist id ="cityList">
                  <option v-for="item in cityList" :key="item.zip">{{item.plz}} - {{item.stadt}}</option>
                </datalist>

              <label
                v-if="!isValidLocation"
                id="invalidLocation" 
                class="control-label text-danger" 
                for="textinput">Die Postleitzahl/Stadt-Kombination ist nicht gültig. Bitte gib eine gültige Kombination aus der Liste an.
                </label>

              </div>

              <div class="text-center">
                <button 
                  type="button"
                  id="btnResetCity" 
                  class="m-2 btn btn-outline-primary" 
                  @click="resetJobLocation(index)">Ort/PLZ leeren
                </button>
              </div>  

            </div>

            <!-- Work location distance (radius in km in which a job is accepted) -->
            <div class="form-group">
              <label class="col-sm-12 control-label"  for="textinput">So viele Kilometer weg sind auch ok</label>
              <div class="col-sm-12">
                <input type="text" id="workDist" @change="setDirty(index)" v-model="item.perimeter" class="form-control">
              </div>
            </div>

            <!-- Not relevant for Azubi-Version-->
            <!-- <div class="form-group">
              <label class="col-sm-12 control-label"  for="textinput">Wunschgehalt (EUR pro Jahr)</label>
              <div class="col-sm-12">
                <input type="text" id="expectedSalary" step=1.0 @change="formatNumber($event,index)" v-model="item.salary" class="form-control">
              </div>
            </div> -->

            <div class="form-group">
              <div class="col-sm-offset-2 col-sm-12">
                <div class="text-center">
                  <button type="button" class="m-2 btn btn-outline-primary" v-on:click="deleteExpectedJob(index)">Eintrag löschen</button>
                  <button type="button" class="m-2 btn btn-primary" v-on:click="updateExpectedJob(index)" :disabled="!isDirty">Eintrag speichern</button>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>


    <div class="text-center">
      <router-link class="m-2 btn btn-outline-primary" to="/signedin">Zurück</router-link>
    </div>      

</template>
  <!--</div>-->
</div>
</template>

$user

<script>
export default {
  props: ["id"],
  data() {
    return {
      jobIndex: null,
      userid: null,
      isDirty: false,
      isValidJobName: true,
      isValidLocation: true,
      newJob : null,
      jobList: [],
      joblocation: null,
      joblocationList: [],
      zip: null,
      workDist: null,
      expectedSalary: null,
      salary: null,
      cityList: [],
      expectedJobList : [{
        id: null,
        newJob : null,
        job : {
          name: null
        },
        plz: {
          detailed: null,
          stadt: null

        },
        joblocation: null,
        workDist : null,
        expectedSalary: null
      }],
      selectedJobName: null,
      selectedJobID : 0,
      expectedJist: {

      }
    };
  },
  methods: {
    setDirty(index) {

      this.isDirty = false;

      // validation

      this.checkIfJobNameValid(index);
      this.checkIfLocationValid(index);

      if (this.isValidJobName == false || this.isValidLocation == false) {
        return;
      };

      this.setWishJob(index);
      this.setLocation(index);
      this.isDirty = true;
    },

    resetExpectedJob (index) {
      this.expectedJobList[index].job.name = '';      
      this.setDirty(index);
    },

    resetJobLocation(index) {
      this.expectedJobList[index].plz.detailed = '';
      this.setDirty(index);
    },
    
    formatNumber(event, index) {

      this.expectedJobList[index].salary = new Intl.NumberFormat('de-DE', {style: 'decimal'}).format(this.expectedJobList[index].salary.replace(/\./g, ''));
      this.setDirty(index);
    },

    getExpectedJob() {

      this.$user.getExpectedJobList(this.userid)
        .then(response => {
          this.expectedJobList = response;
          this.prepareNewjob();

        })
    },

    checkIfJobNameValid(index) {

      let jobNameFound
      let activeJobName

      activeJobName = this.expectedJobList[index].job.name

      // jobName undefined if job name does not exist
      if (activeJobName === null) {

        this.isValidJobName = false;
        return;

      }
        
      jobNameFound = this.jobList.find(job => job.name === activeJobName);
      
      if (jobNameFound === undefined) {

        
        this.isValidJobName = false;
        return;

      }

      this.isValidJobName = true;
      return;

    },
    
   checkIfLocationValid(index) {

      let plzFound

      // plzFound undefined if plz does not exist
      if (this.expectedJobList[index].plz.detailed === null) {

        this.isValidLocation = false;
        return;

      }

      const activePlz = this.expectedJobList[index].plz.detailed.split(' - ')[0];

      plzFound = this.cityList.find(city => city.plz === activePlz);
      
      if (plzFound === undefined) {

        this.isValidLocation = false;
        return;

      }

      this.isValidLocation = true;
      return;

    },


    setWishJob(index) {


      this.expectedJobList[index].job = this.$jobs.getObjectByName(this.expectedJobList[index].job.name);
      this.expectedJobList[index].job_id = this.expectedJobList[index].job.id;

    },

    setLocation(index) {

      this.expectedJobList[index].plz = this.$city.getObjectByFullName(this.expectedJobList[index].plz.detailed);
      this.expectedJobList[index].plz_id = this.expectedJobList[index].plz.id;
    },
    
    prepareNewjob() {
      this.expectedJobList.unshift({
        id: null,
        newJob : null,
        user_id: this.userid,
        headline: "Neuen Eintrag hinzufügen",
        job : {
        name: null
      },
      plz: {
        detailed: null,
          stadt: null

      },
      joblocation: null,
        workDist : null,
        expectedSalary: null
    });
    },

    getJobs() {
      this.$jobs.getJobList()
        .then(response => {
          this.jobList = response;
        })
    },
    getCityList() {
      this.$city.getCityList()
        .then(response => {
          this.cityList = response;
        })
    },
    
    updateExpectedJob(index) {

      this.$user.updateExpectedJob(this.expectedJobList[index])
      .then(response => {
        this.getExpectedJob();
      });

      if(this.expectedJobList[index].id == null) {        
        this.prepareNewjob();
      }

    },

    deleteExpectedJob(index) {

      this.setWishJob(index);

      this.$user.deleteExpectedJob(this.expectedJobList[index])
      .then(response => {
        this.getExpectedJob();
      });

      if(this.expectedJobList[index].id == null) {        
        this.prepareNewjob();
      }

    }

  },
 
 created() {
    this.userid = this.$router.app.jwtHandler.getIdByJWT();
    this.getJobs();
    this.getCityList();
    this.getExpectedJob();

  }
};
</script>
