<template lang="html">

<div class="container">
	<h1 class="center"> Meine Berufsdaten </h1>
  <div class="row align-items-center mt-3">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header" >
          Meine aktuellen Berufsdaten
        </div>
        <div class="card-body content-center p-3" >
          <form class="form-horizontal justify-content-center" role="form">
            <fieldset>

              <div class="form-group row">
                <label class="col-sm-10 control-label" for="textinput">Mein Berufstitel</label>
                <div class="col-sm-10">
                  <input type="text" placeholder="" id="currentJobList" list="jobList" @change="setJobData()"  v-model="userdata.job.name" class="form-control">
                  <datalist  id ="jobList">
                    <option v-for="item in jobList"> {{item.name}}</option>
                  </datalist>
                </div>
              </div><div class="form-group row">
              <label class="col-sm-10 control-label" for="textinput">Mein Abschluss</label>
              <div class="col-sm-10">
                <input type="text" placeholder="" list="graduationList" id="graduation" @change="setGraduationData()" v-model="userdata.graduation.name" class="form-control">
                <datalist  id ="graduationList">
                  <option v-for="item in graduationList" v-bind:id="item.id" v-bind:value="item.name">{{item.name}}</option>
                </datalist>
              </div>
            </div>
              <div class="form-group row">
                <div class="col-sm-10">
                  <div class="pull-right">
                    <router-link class="btn btn-outline-primary" to="/" >Abrechen </router-link>
                    <button type="button" class="btn btn-primary" v-on:click="updateJobData" :disabled="!isDirty">Speichern</button>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
	<div class="row align-items-center mt-3">&nbsp;
	</div>
	</div>
</template>


<script>
export default {
  props: ["id"],
  data() {
    return {
      isDirty: false,
      jobList: [],
      graduationList: [],
      userdata: {
        job: {
          id: null,
          name: ""
        },
        graduation: {
          id: null,
          name: "",
        }
      }
    };
  },
  methods: {
    setDirty() {
      this.isDirty = true;
    },
    setJobData() {
      this.userdata.job = this.$jobs.getObjectByName(this.userdata.job.name);
      this.setDirty();
    },
    setGraduationData() {
	  this.userdata.graduation =  this.$graduation.getObjectByName(this.userdata.graduation.name);
	  this.setDirty();
    },
    getJobList() {
      this.$jobs.getJobList().then(data => {
        this.jobList  = data;
      })
    },
    getUser(userid) {
	  this.$user.getUserData(userid)
      .then(response => {
        this.userdata = response;
      })
    },
    getGraduationList() {
	  this.$graduation.getGraduationList()
      .then(response => {
        this.graduationList = response;
      })
    },
    updateJobData() {
      if(!this.isDirty) {
        return;
      }
      this.$user.updateUserJob(this.userdata.job.id, this.userdata.graduation.id, this.userdata.id)
      .then(resp => {
        window.scrollTo(0,0);
      })
    }
  },
  created() {
    this.getJobList();
    this.getGraduationList();
    this.getUser(this.$router.app.jwtHandler.getIdByJWT());
  }
};
</script>

