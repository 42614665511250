<template lang="html">
  <div class="container text-center">
    <h1 class="center">Meine Werte</h1>
    <template>
      <div class="card" role="tablist">
        <b-card
          no-body
          class="mb-1"
          v-for="(item, index) in groupdQuestions"
          v-bind:key="item.id"
        >
          <b-card-header
            style="cursor: pointer"
            header-tag="header"
            class="card-header"
            role="tab"
            v-b-toggle="'accordion-' + index"
            v-on:click="reset(index)"
            :id="'accordion-header-' + index"
          >
            {{ item.name }} - ({{ openItems(index) }})
          </b-card-header>
          <b-collapse
            :id="'accordion-' + index"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body class="card-body">
              <div class="row mt-2">
                <div class="col col-centered">
                  <b-progress
                    :value="groupdQuestions[key].data[questionid].currentValue"
                    :max="groupdQuestions[key].data[questionid].maxValue"
                    animated
                  ></b-progress>
                </div>
              </div>

              <div class="row mt-2">
                <div
                  class="col col-centered col-md-offset-2"
                  v-bind:style="compOpacityTop"
                  aria-colspan="2"
                >
                  {{ groupdQuestions[key].data[questionid].itm_stmt1 }}
                </div>
              </div>

              <div class="row mt-4 mb-4">
                <div
                  class="col col-centered d-flex align-items-center justify-content-center"
                >
                  <vue-slider
                    v-model="
                      groupdQuestions[key].data[questionid].user_items[0].value
                    "
                    :min="1"
                    :max="99"
                    :height="250"
                    :tooltip="'none'"
                    :dot-style="{ backgroundColor: '#36abff' }"
                    :dotSize="25"
                    :marks="marks1"
                    :process="false"
                    direction="ttb"
                    v-bind:id="
                      'slider-input-' +
                      index +
                      '-' +
                      groupdQuestions[key].data[questionid].itm_number
                    "
                  ></vue-slider>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col col-centered" v-bind:style="compOpacityBottom">
                  {{ groupdQuestions[key].data[questionid].itm_stmt2 }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col col-centered">
                  <button
                    v-if="isButtonNextQuestion"
                    type="button"
                    class="btn btn-primary pull-right"
                    v-on:click="
                      nextQuestion(groupdQuestions[key].data[questionid], key)
                    "
                    data-dismiss="alert"
                    aria-label="Close"
                    v-bind:id="
                      'buttonSave-' +
                      index +
                      '-' +
                      groupdQuestions[key].data[questionid].itm_number
                    "
                  >
                    {{
                      groupdQuestions[key].data[questionid].buttonDescription
                    }}
                  </button>
                  <button
                    v-if="isButtonFinalQuestion"
                    v-b-toggle="'accordion-' + index"
                    type="button"
                    class="btn btn-primary pull-right"
                    aria-label="Close"
                    v-bind:id="
                      'buttonSave-' +
                      index +
                      '-' +
                      groupdQuestions[key].data[questionid].itm_number
                    "
                  >
                    Fertig
                  </button>
                  <!-- <b-icon icon="check-square" v-if="groupdQuestions[key].data[questionid].user_items[0].itemId" scale="2.5" shift-h="15" variant="success" ></b-icon> -->
                </div>
              </div>
              <div class="row mt-2 text-center">
                <div class="col col-centered">
                  <p class="font-weight-light">Copyright Harald Meyer.</p>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>

    <div class="text-center">
      <br />
      <router-link class="btn btn-outline-primary" to="/signedin"
        >Zurück</router-link
      >
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  props: ["id"],
  data() {
    return {
      isButtonNextQuestion: true,
      isButtonFinalQuestion: false,
      questionid: 0,
      key: 0,
      groupdQuestions: null,
      // value: [5,10], // for multipe dots
      value: null, //für einen Dot
      userid: null,
      marks1: {
        1: "das ist meine Welt",
        25: "eher meine Richtung",
        50: "von beiden etwas",
        75: "eher meine Richtung",
        99: "das ist meine Welt",
      },
    };
  },

  methods: {
    reset(key) {
      this.questionid = 0;
      this.key = key;
      this.isButtonNextQuestion = true;
      this.isButtonFinalQuestion = false;
    },

    openItems(index) {
      let maxValue = this.groupdQuestions[index].data[0].maxValue;

      // 50%-Value
      let neutralValues = 0;

      for (let i = 0; i < maxValue; i++) {
        if (this.groupdQuestions[index].data[i].user_items[0].value == 50) {
          neutralValues++;
        }
      }

      if (neutralValues > 0) {
        let res = maxValue - neutralValues + "/" + maxValue;
        return res;
      } else {
        let res = "fertig";
        return res;
      }
    },

    getItems() {
      this.$user
        .getGroupedQuestions(this.userid)
        .then()
        .then((response) => {
          response.forEach((element) => {
            element.data.forEach((subelement) => {
              if (subelement.user_items[0].value == 0) {
                subelement.user_items[0].value = 50;
              }
            });
          });
          this.groupdQuestions = response;
        });
    },

    updateQuestionValue(item) {
      this.$item.updateItem(item, this.userid);
    },

    nextQuestion(item, key) {
      this.updateQuestionValue(item);
      if (this.questionid == item.maxValue - 1) {
        this.isButtonNextQuestion = false;
        this.isButtonFinalQuestion = true;
      } else {
        this.questionid++;
        this.isButtonNextQuestion = true;
        this.isButtonFinalQuestion = false;
      }
    },
  },

  created() {
    this.userid = this.$router.app.jwtHandler.getIdByJWT();
    this.getItems();
  },

  components: {
    VueSlider,
  },

  computed: {
    compOpacityTop() {
      let val =
        1 -
        Math.round(
          this.groupdQuestions[this.key].data[this.questionid].user_items[0]
            .value / 10
        ) /
          10 +
        0.25;
      return "opacity:" + val + ";";
    },

    compOpacityBottom() {
      let val =
        Math.round(
          this.groupdQuestions[this.key].data[this.questionid].user_items[0]
            .value / 10
        ) /
          10 +
        0.25;
      return "opacity:" + val + ";";
    },
  },
  watch: {
    $route() {},
  },
};
</script>

<style scoped></style>
