"use strict";


var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

// backend_url inluding port 
var userPoolId
var clientId
var region
var identityPoolId


// TARGET_ENV comes from webpack.config.js
if (process.env.TARGET_ENV === 'development') {
      userPoolId = process.env.AWS_USER_POOL_ID_ENV_DEV;
      clientId = process.env.AWS_CLIENT_ID_ENV_DEV;
      region = process.env.AWS_REGION_ENV_DEV;
      identityPoolId = process.env.AWS_IDENTITY_POOL_ID_ENV_DEV;

  }

if (process.env.TARGET_ENV === 'staging') {
    userPoolId = process.env.AWS_USER_POOL_ID_ENV_STAGE;
    clientId = process.env.AWS_CLIENT_ID_ENV_STAGE;
    region = process.env.AWS_REGION_ENV_STAGE;
    identityPoolId = process.env.AWS_IDENTITY_POOL_ID_ENV_STAGE;

}

if (process.env.TARGET_ENV === 'production') {
    userPoolId = process.env.AWS_USER_POOL_ID_ENV_PROD;
    clientId = process.env.AWS_CLIENT_ID_ENV_PROD;
    region = process.env.AWS_REGION_ENV_PROD;
    identityPoolId = process.env.AWS_IDENTITY_POOL_ID_ENV_PROD;

}


// Register user
export let awsCognitoRegisterUser = (mail, pw) => {

          var poolData = { 
            
            UserPoolId : userPoolId,
            ClientId : clientId
          
          };  

          var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    
          let attributeList = [];

          var dataEmail = {
            Name: 'email',
            Value: mail,
          };
          
          let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

          attributeList.push(attributeEmail);

          userPool.signUp(mail, pw, attributeList, null, function(
            err,
            result
          ) {
            if (err) {
              alert(err.message || JSON.stringify(err));
              return;
            }
            var cognitoUser = result.user;
            console.log('user name is ' + cognitoUser.getUsername());
          });   


        };

// Authenticate user
export let awsCognitoAuthenticateUser = (mail, pw) => {

  var authenticationData = {
    Username : mail,
    Password : pw,
  };

  var poolData = { 
    
    UserPoolId : userPoolId,
    ClientId : clientId
  
  };  

  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
  
  var userData = {
    Username : mail,
    Pool : userPool
  };

  var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  return new Promise((resolve, reject) => (
      cognitoUser.authenticateUser(authenticationDetails, {
          
          onSuccess: (result) => resolve(result.getAccessToken().getJwtToken()),
          onFailure: (err) => reject(err),

          })
  ));
  

};

//25.06.2023 - Start and complete forgot password
export let awsCognitoForgotPw = (username) => {
 
  var poolData = { 
    
    UserPoolId : userPoolId,
    ClientId : clientId
  
  };  

  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  
  var userData = {

    Username : username,
    Pool : userPool,

  };
  
  var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
 
      cognitoUser.forgotPassword({
          
          onSuccess: (data) => console.log('CodeDeliveryData from forgotPassword: ' + JSON.stringify(data)),
          onFailure: (err) =>alert(err.message || JSON.stringify(err)),

          });
  

};

//25.06.2023 -Set new password via forgot password flow
export let awsCognitoSetNewPw = (username, verificationCode, newPassword) => {

  var poolData = { 
    
    UserPoolId : userPoolId,
    ClientId : clientId
  
  };  

  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  var userData = {

    Username : username,
    Pool : userPool

  };
  
  var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
 

  return new Promise((resolve, reject) => (
      cognitoUser.confirmPassword(verificationCode, newPassword, {
          
          onSuccess: () => resolve(true),
          onFailure: () => reject(false),

          })
  ));
  

};


//25.06.2023 - Change password within application
export let awsCognitoChangePw = (username, oldPw, newPw) => {

  var poolData = { 
    
    UserPoolId : userPoolId,
    ClientId : clientId
  
  };  

  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  var userData = {

    Username : username,
    Pool : userPool

  };
  
  var cognitoUser = userPool.getCurrentUser()

  new Promise(res => cognitoUser.getSession(res));
  
  // cognitoUser.getUserAttributes(function(err, result) {
  //   if (err) {
  //     alert(err.message || JSON.stringify(err));
  //     return;
  //   }
  //   var i
  //   for (i = 0; i < result.length; i++) {
  //     console.log(
  //       'attribute ' + result[i].getName() + ' has value ' + result[i].getValue()
  //     );
  //   }
  // });


  return new Promise((resolve, reject) => (
      cognitoUser.changePassword(oldPw, newPw, function(err, result) {
          
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        console.log('call result: ' + result);

          })
  ));
  

};

//24.07.2023 - Delete User
export let awsCognitoDeleteUser = () => {

  var poolData = { 
    
    UserPoolId : userPoolId,
    ClientId : clientId
  
  };  

  var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
 
  var cognitoUser = userPool.getCurrentUser()

  new Promise(res => cognitoUser.getSession(res));

  return new Promise((resolve, reject) => (  

    cognitoUser.deleteUser(function(err, result) {

      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      console.log('call result: ' + result);    

      })
  
  ));

}