<template>
  <div id="app">
    <header></header>
    <main>
      <div>
        <b-navbar type="light" variant="light">
          <b-navbar-brand href="#">
            <router-link
              class="my-0 mr-md-auto font-weight-normal"
              to="/signedin"
              v-if="isUser"
            >
              <img :src="`logo.png`" alt="" width="72" height="72" />
            </router-link>
            <router-link
              class="my-0 mr-md-auto font-weight-normal"
              to="/companyhome"
              v-if="isCompany"
            >
              <fa
                class="fa-3x"
                icon="building"
                style="color: #bc0101"
                v-if="isCompany"
              />
            </router-link>
            <router-link
              class="my-0 mr-md-auto font-weight-normal"
              to="/"
              v-if="!isLoggedIn"
            >
              <img :src="`logo.png`" alt="" width="72" height="72" />
            </router-link>
          </b-navbar-brand>
          <b-navbar-nav class="ml-auto">
            <b-nav-item class="ml-3">
              <router-link
                class="text-dark"
                to="/information"
                style="cursor: pointer"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle-info"
                  class="fa-2x"
                  style="color: #198fcf"
                />
              </router-link>
            </b-nav-item>
            <b-nav-item v-if="isUser" class="ml-3">
              <a
                class="text-dark"
                v-b-modal.feedback
                v-if="isLoggedIn"
                style="cursor: pointer"
              >
                <font-awesome-icon
                  icon="fa-solid fa-comment-smile"
                  class="fa-2x"
                  style="color: #198fcf"
                />
              </a>
            </b-nav-item>
            <b-nav-item v-if="isUser" class="ml-3">
              <router-link
                class="text-dark"
                to="/signedin"
                style="cursor: pointer"
              >
                <font-awesome-icon
                  icon="fa-solid fa-up-from-bracket"
                  class="fa-2x"
                  style="color: #198fcf"
                  id="thumbToMainMenue"
                />
              </router-link>
            </b-nav-item>
            <b-nav-item v-if="isUser" class="ml-3">
              <a
                class="text-dark"
                v-on:click="logout()"
                style="cursor: pointer"
                id="thumbLogOut"
              >
                <font-awesome-icon
                  icon="fa-solid fa-right-from-bracket"
                  class="fa-2x"
                  style="color: #198fcf"
                />
              </a>
            </b-nav-item>

            <b-nav-item>
              <b-modal
                v-model="modalShow"
                id="feedback"
                title="Zeit für ein kurzes Feedback"
                hide-footer
              >
                <div class="row p-3">Wie zufrieden bist du mit der Seite?</div>
                <div class="row">
                  <div class="col text-center m-3"></div>
                  <div class="col text-center m-3" style="cursor: pointer">
                    <font-awesome-icon
                      v-if="feedbackThumpUpActive"
                      icon="fa-solid fa-thumbs-up"
                      class="fa-3x"
                      style="color: #198fcf"
                      v-on:click="ToggleThump('thumpUp')"
                    />
                    <font-awesome-icon
                      v-if="!feedbackThumpUpActive"
                      icon="fa-regular fa-thumbs-up"
                      class="fa-3x"
                      style="color: #198fcf"
                      v-on:click="ToggleThump('thumpUp')"
                    />
                  </div>
                  <div class="col text-center m-3" style="cursor: pointer">
                    <font-awesome-icon
                      v-if="feedbackThunpDownActive"
                      icon="fa-solid fa-thumbs-down"
                      class="fa-3x"
                      style="color: #198fcf"
                      v-on:click="ToggleThump('thumpDown')"
                    />
                    <font-awesome-icon
                      v-if="!feedbackThunpDownActive"
                      icon="fa-regular fa-thumbs-down"
                      class="fa-3x"
                      style="color: #198fcf"
                      v-on:click="ToggleThump('thumpDown')"
                    />
                  </div>
                  <div class="col text-center m-3"></div>
                </div>
                <div class="row p-3">
                  <label for="feedbackComment"
                    >Was müsste anders sein, damit du mit der Seite zufriedener
                    bist?</label
                  >
                  <textarea
                    class="form-control"
                    id="feedbackComment"
                    rows="4"
                    v-model="feedbackComment"
                  ></textarea>
                </div>
                <button class="btn btn-outline-primary" @click="sendFeedback">
                  Feedback abgeben
                </button>
              </b-modal>
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar>
      </div>
      <aside class="sidebar"></aside>
      <div class="content">
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert_error"
          id="alert_error"
          v-if="this.$messageHandling.hasError()"
        >
          <div class="d-flex">
            <div>
              <strong>{{ this.$messageHandling.getErrorMessage() }}</strong>
            </div>
            <div class="ml-auto">
              <span
                aria-hidden="true"
                v-on:click="hideError"
                style="cursor: pointer"
                ><strong>X</strong></span
              >
            </div>
          </div>
        </div>

        <div
          class="alert alert-info alert-dismissible fade show"
          role="alert"
          id="successfullLogin"
          v-if="this.$messageHandling.isSuccess()"
        >
          <div class="d-flex">
            <div>
              <strong>{{ this.$messageHandling.getSuccessMessage() }}</strong>
            </div>
            <div class="ml-auto">
              <span
                aria-hidden="true"
                v-on:click="hideSuccess"
                style="cursor: pointer"
                ><strong>X</strong></span
              >
            </div>
          </div>
        </div>

        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import requestHandler from "./api/RequestHandling";

const regeneratorRuntime = require("regenerator-runtime");

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      posts: null,
      feedbackComment: null,
      feedbackThumpUpActive: false,
      feedbackThunpDownActive: false,
      test: "ein Test",
      isLoggedIn: false,
      isUser: false,
      isCompany: false,
      logoutButton: true,
      modalShow: null,
      logo: require("./assets/logo.png"),
      params: {
        client_id:
          "427642123675-h9e0e7g2ngnph027ceps73mm8m7sveq7.apps.googleusercontent.com",
      },
    };
  },
  created() {
    this.checkAuthentificationStatus();
    this.$requestHandling.backend_url = this.$router.app.backend_url;
  },

  components: {
    requestHandler,
    VueSlider,
  },
  methods: {
    ToggleThump(thumpPressed) {
      //thumpUp or ThumpDown
      if (thumpPressed == "thumpUp") {
        if (this.feedbackThumpUpActive == true) {
          this.feedbackThumpUpActive = false;
          this.feedbackThunpDownActive = false;
        } else {
          this.feedbackThumpUpActive = true;
          this.feedbackThunpDownActive = false;
        }
      }
      if (thumpPressed == "thumpDown") {
        if (this.feedbackThunpDownActive == true) {
          this.feedbackThunpDownActive = false;
          this.feedbackThumpUpActive = false;
        } else {
          this.feedbackThunpDownActive = true;
          this.feedbackThumpUpActive = false;
        }
      }
    },
    PushTo(route) {
      // Avoid redundant navigation to current location error - check also main.js router.beforeEach-section
      try {
        this.$router.push(route);
      } catch (error) {
        console.log("Error");
        if (!(error instanceof NavigationDuplicated)) {
          throw error;
        }
      }
    },
    checkAuthentificationStatus() {
      if (this.$auth.isAuthenticated()) {
        this.isLoggedIn = true;
        localStorage.setItem("userType", "user");
        this.isUser = true;
        this.isCompany = false;
      } else if (this.$company_auth.isCompanyAuthenticated()) {
        this.isLoggedIn = true;
        localStorage.setItem("userType", "company");
        this.isUser = false;
        this.isCompany = true;
      } else {
        this.isLoggedIn = false;
        this.isUser = false;
        this.isCompany = false;
        localStorage.setItem("userType", "");
      }
    },

    sendFeedback() {
      let feedbackThump = null; // send null if there is no vote
      if (this.feedbackThumpUpActive == true) {
        feedbackThump = true;
      }
      if (this.feedbackThumpDownActive == true) {
        feedbackThump = false;
      }
      var param = {
        user_id: this.$router.app.jwtHandler.getIdByJWT(),
        rating: this.feedback,
        thumbrating: feedbackThump,
        page: this.$route.name,
        comment: this.feedbackComment,
      };
      this.$requestHandling
        // .post(param, this.$router.app.backend_url + "feedback")
        .post(this.$requestHandling.backend_url + "feedback", param)
        .then((response) => {
          this.$messageHandling.handleSuccess(
            "Vielen Dank für dein Feedback! -- Dein Input hilft uns die Seite für dich perfekt zu machen"
          );
        });
      this.value = 0;
      this.modalShow = false;
    },

    logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("cognitoToken");
      localStorage.removeItem("company");
      localStorage.removeItem("userType");
      this.isLoggedIn = false;
      this.isUser = null;
      this.isCompany = null;
      this.$router.app.loggedIn = false;
      this.$router
        .push({
          name: "home",
          params: { successMessage: "Du hast dich erfolgreich abgemeldet" },
        })
        .catch((err) => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // But print any other errors to the console
            logError(err);
          }
        });
    },

    hideSuccess() {
      this.$messageHandling.hideSuccessMessage();
    },

    hideError() {
      this.$messageHandling.hideErrorMessage();
    },
  },

  watch: {
    $route(res) {
      this.checkAuthentificationStatus();
      if (res.params.errorMessage) {
        this.$messageHandling.handleError(res.params.errorMessage);
      }

      if (res.params.successMessage) {
        this.$messageHandling.handleSuccess(res.params.successMessage);
      }
    },
  },
};
</script>
