<template lang="html">
  <div class="container-lg">
    <div class="row">
      <div class="col-sm-12 col-md-4 mt-3">
        <div class="card">
           <div class="card-body text-center"
				style="cursor: pointer;" 
		   		v-on:click="PushTo('/companymydata')">
				<fa class="fa-5x" icon='file-pen' style="color: #bc0101;" ></fa>
          	</div>
          <div class="text-center" 
		  	>Eingabe Firmendaten</div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 mt-3">
		<div class="card">
           <div class="card-body text-center"
				style="cursor: pointer;" 
		   		v-on:click="PushTo('/companymydata')">
				<fa class="fa-5x" icon='building-user' style="color: #bc0101;" ></fa>
          	</div>
          <div class="text-center" 
		  	>Jobs bearbeiten</div>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 mt-3">
		<div class="card">
           <div class="card-body text-center"
				style="cursor: pointer;" 
		   		v-on:click="logout()">
				<fa class="fa-5x" icon='right-from-bracket' style="color: #bc0101;" ></fa>
          	</div>
          <div class="text-center" 
		  	>App verlassen!</div>
        </div>
      </div>
     </div>
     <div class="row">
      
     </div>
  </div>
</template>


<script>
import axios from 'axios';
export default{
	props:['id'],
	data() {
		return{
			password: null,
		}
	},
	methods:{
		PushTo(route) { // Avoid redundant navigation to current location error - check also main.js router.beforeEach-section
      		try {
        		this.$router.push(route);     
      		} catch(error) {
          		if (!(error instanceof NavigationDuplicated)) {
            	throw error;
          		}          
        	}
    	},
		logout() {
			localStorage.removeItem("user");
			localStorage.removeItem("cognitoToken");
			localStorage.removeItem("company");
			localStorage.removeItem("userType");
			this.isLoggedIn = false;
			this.isUser = null;
			this.isCompany = null;
			this.$router.app.loggedIn = false;
			this.$router.push({
        		name: "home",
        		params: { successMessage: "Du hast dich erfolgreich abgemeldet" },
      		})
     		.catch(err => {
				// Ignore the vuex err regarding  navigating to the page they are already on.
				if (
					err.name !== 'NavigationDuplicated' &&
					!err.message.includes('Avoided redundant navigation to current location')
				) {
					// But print any other errors to the console
				logError(err);
			}
  }); 
    },
	},

	created(){

	},
  watch: {
    '$route'() {
    }
  }
}
</script>