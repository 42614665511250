<template>
  <div class="callback"><h1>CALLBACK</h1></div>
</template>

<script>
export default {
  name: 'callback',
  mounted() {
	  this.$router.push({name: 'home'});
    // this.$auth.handleAuthentication().then((data) => {
    //   this.$router.push({ name: 'home' })
    // })
  }
}
</script>